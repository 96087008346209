.andon-waiting-shipping-orders {
  display: flex;
  height: 100%;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--textSecondary);
    }
  }

  .content-table {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .line {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;

      span {
        width: fit-content;
        font-weight: 600;
        font-size: 1.2rem;

        &.part-number {
          font-size: 1.5rem;
        }
      }
    }
  }
}
