@import "../../../theme/variables.scss";

.group-container {
  width: 100%;
  max-width: 790px;
  height: 100%;

  h3.page-header {
    font-size: 16px;
    font-weight: 600;
    color: var(--textPrimary);
    margin: 0;
  }

  .page-header {
    border-bottom: 1px solid var(--systemBackground);
    padding-bottom: 10px;
  }

  .custom-input-container {
    width: 100%;
    flex: auto;
  }

  .p-colorpicker-preview {
    height: 40px;
    width: 40px;
  }
}
