.fade-in {
  opacity: 0;
  animation: fade-in linear;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @for $i from 1 through 500 {
    &:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.1}s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
