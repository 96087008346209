.inventory-status-badge {
  .wrapper {
    background: var(--yellow-100);
    width: fit-content;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: var(--yellow-600);
      font-size: 14px;
      font-weight: 600;
    }

    &.in-progress {
      background: var(--blue-100);

      span {
        color: var(--blue-600);
      }
    }

    &.paused {
      background: var(--gray-100);

      span {
        color: var(--gray-600);
      }
    }

    &.canceled {
      background: var(--red-100);

      span {
        color: var(--red-600);
      }
    }

    &.finished {
      background: var(--green-100);

      span {
        color: var(--green-600);
      }
    }

    &.remove-bg {
      background: transparent;
    }
  }
}