// Documentation modal section title
.documentation-modal {
  .content {
    min-width: 100%;
    /* margin: -20px; */
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .documentation-group {
      display: flex;
      justify-content: space-between;
      width: 50%;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 20px;
      font-size: 14px;

      .documentation-item {
        font-weight: 500;
      }

      .documentation-key-hot {
        display: flex;
        gap: 5px;

        .tag {
          font-weight: 600;
          padding: 4px;
          background-color: #f2f4f7;
          color: #344054;
          border-radius: 6px;
        }

        .no-tag {
          font-weight: 500;
          padding: 4px;
        }
      }
    }
  }

  .footer {
    border-top: solid 1px var(--systemBackground);
    padding: 10px;
    margin: -20px;
    margin-top: 30px;

    .items {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .item {
        font-weight: 500;

        .tag {
          font-weight: 600;
          padding: 4px;
          background-color: #f2f4f7;
          color: #344054;
          border-radius: 6px;
        }
      }
    }
  }
}
