@import "../../../../theme/variables.scss";

.upload-data-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  height: 437px;
  width: 600px;
  padding: 0 30px 2rem 40px;

  .upload-file-container {
    height: 287px;
    width: 515px;
    display: flex;
    flex-direction: column;
    border: 3px dashed var(--databaseUploadSpaceColorOnHover);
    border-radius: 12px;
    margin: 20px 0px 20px 0px;
    transition: 0.2s;

    .upload-file-wrapper {
      display: flex;
      cursor: pointer;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      transition: 0.2s;

      .upload-file-img {
        height: 60px;
        width: 144px;
      }

      .upload-file-input {
        display: flex;
        display: none;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        margin: 0;
      }

      .upload-input-description {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--textPrimary);
      }
    }

    .upload-file-wrapper:hover {
      border-radius: 12px;
      background-color: var(--databaseUploadSpaceColorOnHover);
    }
  }

  .upload-file-format {
    display: flex;
    gap: 1rem;
  }

  .upload-data-dialog-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 1rem 1rem 0;
  }
}

.form-container {
  display: flex;
  flex-direction: column;

  .data-from-to-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 437px;
    width: 100%;
    gap: 10px;

    .progress-contet {
      padding-top: 10px;
      display: flex;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;

      .progress-header {
        display: flex;
        align-items: center;
        padding: 0px 40px 0px 40px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .bar-wrapper {
        display: flex;
        align-items: center;
        height: 30px;
        gap: 20px;
        padding: 0px 40px 0px 40px;

        .bar {
          position: relative;
          height: 100%;
          width: 100%;

          .background-fill,
          .value {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 3px;

            &.background-fill {
              background: #f5f5fd;
            }
          }

          .exemple-bar-wrapper {
            height: 6px;
            width: 100%;

            .exemple-bar {
              height: 100%;
              width: 100%;
              background: #f5f5fd;
            }
          }
        }

        .percentage-label {
          white-space: nowrap;
          color: $textSecondary;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .data-from-to-dialog-wrapper {
      display: flex;
      width: 100%;
      height: 335px;
      flex-direction: column;
      padding: 0px 40px 0px 40px;
      overflow-y: auto;

      .data-association-dialog-title {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0 1rem 1rem 0;
      }

      .column-mapping {
        display: flex;
        width: 100%;
        height: 85%;
        gap: 35px;

        .column-mapping-left {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 50%;

          .column-database-wrapper {
            height: 55px;

            .column-mapping-row {
              display: flex;
              width: 100%;

              .column-mapping-input {
                width: 100%;
              }
            }
          }
        }

        .column-mapping-right {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 50%;

          .column-mapping-item {
            height: 55px;

            .column-mapping-dropdown {
              display: flex;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    align-items: self-end;
    flex-direction: row;
    padding: 20px 31px;
    border-top: 1px solid var(--systemBackground);
    width: 100%;
    height: 19%;
    gap: 1rem;
    justify-content: flex-end;

    .buttons-container {
      display: flex;

      .cancel-btn {
        display: flex;
        justify-content: flex-end;
      }

      .submit-btn {
        display: flex;
        justify-content: flex-end;
      }

      .stop-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.p-dialog .p-dialog-header {
  padding-bottom: 8px;
}