@import "../../../../../theme/variables.scss";

.inventory-details-content {
  .backdrop {
    background: $systemForeground;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  &.fullscreen {
    border-radius: 0;
    min-height: 100%;
    height: fit-content;
    width: 100%;
    position: absolute;
    padding: 18px;
    background: rgba(16, 24, 40, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .backdrop {
      min-height: calc(100vh - 36px);
      height: 100%;
      width: 100%;
    }
  }

  .icon {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px;

    &:hover {
      background: $systemBackground;
      cursor: pointer;
    }
  }

  .header {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom: 1px solid var(--systemBackground);
    .area-selection {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      .selector {
        display: flex;
        gap: 10px;
        align-items: center;

        .name {
          color: var(--textPrimary);
          font-size: 18px;
          font-weight: 600;
        }
      }

      .area-header-actions-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;

        .unprocessed-image-button {
          color: var(--secondaryButtonFontColor);
          .unprocessed-image-button-label-wrapper {
            display: flex;
            align-items: center;

            .badge-image-counts {
              display: flex;
              justify-content: center;
              width: 22px;
              height: 22px;
              font-size: 15px;
              text-align: center;
              align-items: center;
            }
          }
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      gap: 30px;

      .p-input-icon-left {
        .pi-search {
          padding-left: 12px;
          background-color: var(--inputBackgroundColor);
        }

        .search-input {
          width: 100%;
          padding-left: 30px;
        }
      }

      .p-multiselect,
      .p-inputtext {
        min-width: 240px;
        background-color: var(--inputBackgroundColor);
        border: none;
      }
      .p-multiselect .p-multiselect-trigger {
        background-color: var(--inputBackgroundColor);
        border: none;
      }

      .title {
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 600;
      }

      .value {
        color: var(--gray-500, #667085);
        font-size: 16px;
        font-weight: 600;
      }

      .accuracy {
        .value {
          color: #03ceb7;
        }
      }

      .buttons {
        display: flex;
        gap: 10px;
        margin-left: auto;

        .p-button {
          width: fit-content;
          padding: 10px;
        }
      }
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    height: 100%;

    .no-location-counts {
      margin: 0 auto;
    }
    .aisle {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .label {
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }

      .location-panel-header {
        padding: 20px;
        display: flex;
        min-height: 84px;
        align-items: center;
        gap: 10px;
        background: var(--inventoryAreaColumnHeaderBackgroundColor);
        border-radius: 6px 6px 0 0;
        border: 1px solid var(--inventoryAreaColumnHeaderBackgroundColor);
        cursor: pointer;

        .label {
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 700;
          margin: 0;
        }

        .status {
          display: flex;
          gap: 6px;
          align-items: center;
          border-radius: 20px;
          background: var(--blue-100);
          padding: 6px 10px;

          p {
            color: var(--blue-500);
            font-size: 14px;
            font-weight: 600;
          }

          &.matches {
            background: var(--green-100);

            p {
              color: var(--green-500);
            }
          }

          &.divergent {
            background: var(--red-100);

            p {
              color: var(--red-500);
            }
          }
        }

        .header-buttons {
          // display: none;
          margin-left: auto;
          .p-button {
            padding: 10px;
          }
        }

        &:hover {
          .header-buttons {
            display: block;
          }
        }
      }
    }
  }
}
