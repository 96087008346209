@import "../../../../../theme/variables.scss";

.location-pictures-view {
  width: 60vw;
  height: 60vh;
  padding: 20px 30px;
  border-top: 1px solid var(--systemBackground);

  .row {
    display: flex;
    gap: 20px;
  }

  .loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }

  .icon {
    padding: 4px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: none;

    &:hover {
      background: var(--Gray-200, #e1e8ed);
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .header {
    min-height: 32px;

    &:hover {
      .icon {
        display: flex;
      }
    }
  }

  .images-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .user-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .header {
        display: flex;
        align-items: center;
        gap: 10px;

        .user-name {
          color: var(--textTertiary);
          font-size: 16px;
          font-weight: 700;
          margin: 0;
        }
      }

      .image-wrapper {
        padding: 10px 6px 6px 6px;
        background: var(--systemBackground);
        border-radius: 6px;
        width: 162px;
        height: 210px;

        .images-preview-thumbnail {
          height: 150px !important;
          width: 150px !important;
        }

        .icon {
          display: none;
        }

        &:hover {
          .icon {
            display: flex;
          }
        }

        .image-header {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;
          min-height: 32px;

          .filename-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-grow: 1;

            .img-icon {
              flex-basis: 20px;
              width: 20px;
            }

            .filename {
              flex-grow: 1;
              margin: 0;
              color: var(--textPrimary);
              font-size: 12px;
              font-weight: 500;
              text-overflow: ellipsis;
              flex-basis: 0;
            }
          }
        }

        .p-image-preview-container {
          border-radius: 6px;
          background-color: #d1d1d1;
          display: flex;
          justify-content: center;
        }

        img {
          height: 150px;
          border-radius: 6px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .image-menu {
    .menu-title {
      margin: 0;
      padding: 12px 16px;
    }
  }
}

.location-pictures-view-dialog {
  .p-dialog-content {
    padding: 0;
  }
}
