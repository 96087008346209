@import "../../../../../theme/variables.scss";

.location-panel {
  .p-panel-content {
    border: 1px soild var(--inventoryAreaBackgroundColor);
    padding: 0;
    background-color: var(--inventoryAreaBackgroundColor);
  }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;

  .stats {
    display: flex;
    gap: 20px;

    .stat {
      display: flex;
      align-items: center;
      border: 1px solid var(--tableTrBorderColor);
      border-radius: 6px;
      padding: 6px 10px;
      gap: 20px;

      .info {
        p {
          margin: 0;
        }

        .value {
          color: var(--textTertiary);
          font-size: 20px;
          font-weight: 600;
        }

        .label {
          color: var(--textSecondary);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .p-button {
      margin: auto 0 auto auto;
      p {
        font-size: 14px;
        font-weight: 500;
      }

      &.delete {
        margin-left: 0;
      }
    }
  }

  .location-table {
    display: flex;
    position: relative;

    .users {
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;
      width: 100%;

      .users-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    table {
      background: var(--systemBackground);
      border-radius: 0 0 0 8px;
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1px;

      td,
      th {
        padding: 12px 24px;
        height: 60px;
      }

      th {
        color: var(--textPrimary);
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        white-space: nowrap;
      }

      td {
        border-top: 1px solid var(--tableTrBorderColor);
        color: var(--gray-500, #667085);
        font-size: 14px;
        font-weight: 400;
      }
    }

    .column-header {
      border-radius: 8px 0 0 0;
      border: 1px solid var(--systemBackground);
      padding: 10px 20px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .main-column {
      .column-header {
        background: var(--inventoryAreaColumnHeaderBackgroundColor);
      }

      .edit-result-button {
        cursor: pointer;
        &:hover {
          background: $painelBgColorHover;
        }
      }
    }

    .user-column {
      width: 100%;

      table {
        background: $systemForeground;
        border-right: 1px solid var(--tableTrBorderColor);
        border-bottom: 1px solid var(--tableTrBorderColor);
      }
      .column-header {
        border-radius: 0;
        background: var(--tableHeaderBackground);
        display: flex;
        justify-content: space-between;
        gap: 10px;
        min-width: fit-content;

        .icons {
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          .p-button.galery {
            flex-basis: 40px;
            height: fit-content;
            width: fit-content;
            padding: 6px;
          }
          .p-button.finish {
            padding: 6px;
            width: fit-content;
            height: fit-content;

            &.p-disabled {
              background: #eaecf0;
              border: none;
            }
          }
        }

        &.last {
          border-radius: 0 8px 0 0;
        }
      }

      td,
      th {
        border-bottom: 1px solid var(--tableTrBorderColor);
      }
    }
  }

  .p-inplace-content {
    display: flex;
    gap: 8px;

    .p-inputnumber-input {
      max-width: 80px;
    }

    .p-inplace-content-close {
      margin: 0;
      gap: 0;
    }
  }
}

.gallery-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(black, 0.9);
  overflow: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .close-icon {
    margin-left: auto;
    z-index: 1001;

    &:hover {
      cursor: pointer;
    }
  }

  .gallery {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.result-edit-op {
  display: flex;
  gap: 8px;
  padding: 10px;
}
