@import "../../../../theme/variables.scss";

.area-edit {
  .area-edit-container {
    padding: 34px;
    margin-right: 65px;
    height: 100%;
    .area-edit-screen {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .top-bar {
        display: flex;
        width: 100%;
        background: $systemForeground;
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid var(--systemBackground);

        .PageHeader {
          background: transparent;
        }

        .icon {
          color: $primary;
          margin-right: 20px;
          cursor: pointer;
          font-size: $defaultFontSize;
          padding: 2px 0;
          transition: scale 300ms;
        }

        .title {
          color: $textPrimary;
        }
      }

      .bottom-container {
        width: 100%;
        height: 100%;
        display: flex;
      }

      .map-edit-container {
        width: 100%;
        height: 100%;
        display: flex;
        .right-column {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: auto;

          .map-container {
            height: 100%;
            overflow: auto;
          }

          .buttons-bar {
            width: 100%;
            background: $systemForeground;
            border-radius: 0 0 6px;
            border-top: 1px solid var(--systemBackground);
            padding: 12px 30px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .button-container {
              display: flex;
              justify-content: flex-end;
              gap: 8px;
            }
          }
        }
      }
    }
  }
}
