.rack-model {
  display: flex;
  flex-direction: column;
  align-items: center;

  .location {
    border: 1px dashed #4146ff;
    border-radius: 6px;
    width: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 6px;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;

    white-space: nowrap;

    .level-color {
      height: 15px;
      width: 15px;
      border-radius: 2px;
      margin-right: 5px;
    }

    .name {
      color: var(--rackShelvesLabelColor);
      font-size: 12px;
      font-weight: 500;
    }
  }

  .top-shelf {
    width: 100%;
    height: 100px;

    .locations {
      display: flex;
      height: 100%;
      gap: 10px;
    }

    .shelf {
      height: 100%;
    }
  }

  .rack {
    display: flex;

    .side-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .rack-side {
      display: flex;
      background: var(--rackStructureColor);
      width: 12px;

      .side,
      .interior {
        width: 4px;
        height: 450px;
      }

      .interior {
        --s: 10px;
        background:
          radial-gradient(
              circle closest-side,
              var(--rackStructureDetailsColor) 98%,
              #0000
            )
            0 0/100% var(--s),
          linear-gradient(var(--rackStructureDetailsColor) 50%, #0000 0) 0
            calc(var(--s) / 2) / 100% calc(2 * var(--s));
      }
    }

    .rack-middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 300px;

      .base {
        background: var(--systemBackground);
        height: 16px;
        width: 100%;
        padding-top: 6px;

        .line {
          background: var(--rackInternStructureColor);
          width: 100%;
          height: 6px;
        }
      }

      .shelves-container {
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .shelves {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
      }

      .shelf {
        width: 100%;
        display: flex;
        flex-direction: column;

        .locations {
          flex: 1;
          padding: 8px 10px;
          display: flex;
          height: 100%;
          gap: 10px;
        }
      }

      .bottom-space {
        height: 30px;
        width: 100%;
      }
    }
  }

  .custom-shelf-name-op {
    display: flex;
    gap: 20px;

    .p-overlaypanel-content {
      padding: 0;
    }
  }
}
