.productivity-horizontal-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    gap: 20px;

    .bar {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;

      .background-fill,
      .goal-bar,
      .value {
        position: absolute;
        width: 100%;
        height: 60%;
        border-radius: 3px;

        &.background-fill {
          background: #f5f5fd;
        }
      }

      .exemple-bar-wrapper {
        height: 6px;
        width: 100%;

        .exemple-bar {
          height: 100%;
          width: 100%;
          background: #f5f5fd;
        }
      }

      .goal-bar {
        border-right: 3px solid var(--gray-500);
        height: 100%;
        width: 30%;
      }
    }

    .percentage-label {
      color: var(--gray-700, #344054);
      font-size: 14px;
      font-weight: 600;
    }
  }

  .goal-marker {
    border-left: 4px solid var(--gray-300, #e1e8f0);
    padding-left: 5px;
    padding-top: 8px;
  }

  .legend {
    display: flex;
    column-gap: 10px;
    row-gap: 6px;
    flex-wrap: wrap;
    margin-top: 18px;

    p {
      font-weight: 500;
      font-size: 14px;
      color: var(--textSecondary);
      margin: 0;
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }
  }
}
