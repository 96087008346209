.template-management {
  display: flex;
  height: 100%;
  color: var(--textPrimary);
  flex-direction: column;
  padding: 34px;

  .page-container {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;

    .template-container {
      display: flex;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      background: var(--systemForeground);
      border-radius: 6px;
      border: solid 1px var(--painelBorderColor);
      flex-direction: column;

      .template-body {
        display: flex;
        flex-grow: 1;
        height: 100%;
        border-top: 1px solid var(--systemBackground);
        .qr-code-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 100%;

          .qr-code-wrapper-container {
            display: flex;
            flex: 1 1;
            flex-direction: row;
            gap: 20px;
            height: 100%;
            width: 100%;
            padding: 0px 30px;
            margin-bottom: 30px;
            overflow: scroll;

            .qr-code-input-area-container {
              display: flex;
              flex-direction: column;
              flex: 1 1;

              .template-code-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
                padding: 19px 0px 10px 0px;
                background: var(--systemForeground);

                .template-code-content {
                  display: flex;
                  align-items: flex-start;
                  width: 100%;
                  flex-direction: column;
                  gap: 10px;

                  .template-form-content {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 66px;

                    .template-name-input-wrapper .input-container {
                      width: 145px !important;
                    }

                    .template-isActive-button-wrapper {
                      display: flex;
                      align-self: center;
                    }
                  }

                  .template-tags-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 50%;
                    .dynamic-tags-title {
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      font-size: 16px;
                      font-weight: 600;
                      color: var(--textPrimary);
                    }
                  }
                  .card-additional-info {
                    display: flex;
                    height: 20px;
                    width: 100%;
                    flex-wrap: wrap;
                    gap: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;

                    .template-tags {
                      height: 20px;
                      border-radius: 3px;
                      font-size: 14px;
                      font-weight: 600;
                      color: var(--textPrimary);
                    }
                  }
                }
              }

              .qr-code-textarea {
                height: 100% !important;
                overflow: auto !important;
                border-radius: 0px !important;
              }
            }

            .zpl-image-preview-container {
              display: flex;
              flex-direction: column;
              flex: 1 1;
              .zpl-image-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: var(--rackExampleBackgroundColor);
                padding: 10px 10px 30px;
                height: 88%;
                margin-bottom: 10px;

                .header {
                  display: flex;
                  justify-content: space-between;
                  padding: 10px 10px;
                  color: var(--textTertiary);

                  .span {
                    font-size: var(--defaultFontSize);
                    font-weight: 500;
                    color: var(--textTertiary);
                  }
                }
                .zpl-preview {
                  display: flex;
                  flex-direction: column;
                  max-height: 569px;
                  max-width: 400px;
                  align-self: center;
                  justify-content: center;
                  overflow-x: auto;
                  height: 100%;

                  .qr-code-image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-height: 547px;
                    max-width: 400px;
                    width: 100%;
                    background-color: var(--systemBackground);
                    border-radius: 6px;
                    border: solid 1px var(--painelBorderColor);
                  }
                }
              }
              .template-zpl-image-dimensions {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
                height: 60px;
                justify-content: space-between;
                gap: 8px;

                .template-size-setting-wrapper {
                  display: flex;
                  flex-direction: row;
                  gap: 20px;
                  width: 80%;
                  height: 58px;

                  .template-dpi-input {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                  }

                  .template-size-separator {
                    display: flex;
                    align-items: center;
                    height: 0px;
                  }

                  .template-width-input {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    .template-width-input-container {
                      display: flex;
                      flex-direction: column;
                    }
                  }
                  .template-height-input {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                  }
                }
              }

              .image-generate-preview-button {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-end;
                justify-content: center;
                height: 61px;
                width: 166px;
                gap: 8px;

                .generate-preview-button {
                  width: 100%;
                  height: 37px;
                  color: var(--primary);
                }
              }
            }
          }

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            border-top: 1px solid var(--systemBackground);
            padding: 10px 30px;
            height: 60px;

            .template-manage-button-wrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              .btn-cancel-template {
                color: var(--textPrimary);
              }
            }
          }
        }
      }
    }
  }
}
