@import "../../../theme/variables.scss";

.nav-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 65px;
  background-color: $systemForeground;
  box-shadow: $defaultBoxShadow;
  z-index: 10;
  box-shadow: 0px -2px 20px 0px rgba(70, 98, 172, 0.1);

  .product-menu-wrapper {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo-menu-wrapper {
      display: flex;
      align-items: center;

      .btn-product-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        padding: 8px;
        cursor: pointer;

        &:hover {
          background-color: $systemBackground;
        }

        .app-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;

          .app-icon {
            height: 28px;
          }
        }
      }
    }

    .menu-manage-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 2px;

      .btn-manage-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        padding: 8px;
        cursor: pointer;
      }
    }
  }

  .nav-menu-search {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 50%;

    .search-input {
      display: flex;
      height: 40px;
      width: 100%;
      max-width: 350px;
      padding: 10px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 20px;
      background: var(--inputBackgroundColor, #fff);
      transition: 0.4s;

      .icon-search {
        display: flex;
        justify-content: space-between;

        svg {
          top: 17px;
        }
      }

      .p-inputtext {
        flex-grow: 1;
        background-color: $inputBackgroundColor;
      }

      .tag-region {
        margin-right: 8px;
        display: flex;
        gap: 4px;

        .tag-hot-key {
          background-color: $iconHotKeyBg;
          color: $iconHotKeyLabelColor;
          padding: 4px;
          font-weight: 500;
          border-radius: 2px;
          border: solid 1px $iconHotKeyBorderColor;
        }
      }
    }

    @media only screen and (max-width: 750px) {
      .search-input {
        display: none;
      }
    }
  }

  .menus-container {
    display: flex;
    align-items: center;
    gap: 30px;

    .context-menus {
      display: flex;
      align-items: center;
      gap: 30px;

      .icons-settings-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-right: 1px solid var(--systemBackground);
        padding-right: 20px;

        .support-icon {
          padding: 4px;
          color: $iconNavBarDefaultColor !important;
        }

        .support-icon:hover {
          background-color: $iconNavBarActiveColor !important;
          border-radius: 16px;
          padding: 4px;
        }

        .settings-icon {
          color: $iconNavBarDefaultColor !important;
          padding: 4px;
        }

        .settings-icon:hover {
          background-color: $iconNavBarActiveColor !important;
          border-radius: 16px;
          padding: 4px;
        }
      }

      .client-logo-img {
        display: flex;
        height: 27px;
        width: 96px;
      }
    }

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.user {
        height: 40px;
        width: 40px;
        background-color: $iconHotKeyBg;
        border-radius: 50%;
        margin-right: 10px;

        path {
          stroke: $tableHeaderTitle;
        }
      }

      &.user:hover {
        background-color: $systemBackground;
        border-radius: 50%;
      }
    }
  }

  .user-menu {
    display: flex;
    justify-content: center;
    width: 340px;

    .user-menu-wrapper {
      width: 340px;
      font-size: var(--defaultFontSize);
      color: var(--textPrimary);

      .user-identification {
        display: flex;
        width: 340px;
        flex-direction: row;
        align-items: center;
        gap: 14px;
        padding: 12px;
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;

        .user-avatar {
          display: flex;
        }

        .user-info-wrapper {
          display: flex;
          width: 340px;
          flex-direction: column;
          align-items: start;
          flex-grow: 1;

          .user-name {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .user-email {
            width: 280px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .user-domain {
            width: 280px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .keyboard-shortcut-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        height: 40px;
        border-top: solid 1px var(--systemBackground);
      }

      .keyboard-shortcut-btn:hover {
        cursor: pointer;
        background-color: var(--systemBackground);
      }

      .theme-btn {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;

        .theme-select {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-grow: 1;

          #theme-selector-btn {
            background-color: var(--systemBackground);
            display: flex;
            height: 28px;
            justify-content: space-between;
            padding: 0.23px 0.1px;
            margin-right: 0px;
            border: 1px solid var(--systemBackground);
            border-radius: 8px;
            font-size: 5px;
            transition: 0.2s;

            .selector {
              height: 100%;
              display: flex;
              align-items: center;
              padding: 7px;
              padding-left: 5px;
              border-radius: 8px;
              cursor: pointer;
              transition: 0.2s;

              &.lightModeActive {
                border-radius: 7px 2px 2px 7px;
                margin-top: 0.1px;
                background-color: #e5e7eb;
              }

              &.darkModeActive {
                border-radius: 2px 7px 7px 2px;
                margin-top: 0.1px;
                background-color: var(--systemForeground);
              }
            }
          }
        }
      }

      .language-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 16px;
        height: 40px;
        margin-bottom: 12px;

        .language-select {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-grow: 1;

          .language-selector {
            width: 60%;
            display: flex;
            justify-content: end;

            .language-dropdown {
              width: 90%;
            }
          }
        }
      }

      .logout-btn {
        display: flex;
        position: relative;
        top: 2px;
        align-items: center;
        border-radius: 0px 0px 7px 7px;
        gap: 10px;
        padding: 10px 16px;
        border-top: solid 1px var(--systemBackground);
        color: var(--systemForeground);

        &:hover {
          background: $systemBackground;
          cursor: pointer;
        }

        .label {
          margin: 0;
          color: var(--textPrimary);
        }
      }
    }
  }
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0px;
}

.p-overlaypanel:after {
  border-width: 0px;
  left: none;
}

.p-overlaypanel:before {
  display: contents;
}

.p-overlaypanel:before {
  display: contents;
}

.nav-menu-product-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 384px;
  background-color: $systemForeground;
  z-index: 1000;
  border-radius: 6px;
  border: solid 1px $painelBorderColor;
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(30, 40, 60, 0.1);

  .nav-menu-product-menu-header {
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px 8px;

    .nav-menu-product-menu-header-title {
      color: $textPrimary;
      font-size: 14px;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .nav-menu-product-menu-content {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    padding-bottom: 10px;
    gap: 10px;
    height: 100%;

    .product-option-label {
      display: flex;
      align-items: center;
      gap: 12px;
      color: $textSecondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      padding: 8px;
    }

    .product-option-label:hover {
      cursor: pointer;
      color: $textPrimary;
      background-color: $systemBackground;
      border-radius: 6px;
      padding: 8px;
    }
  }

  .nav-menu-product-menu-footer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 8px 10px;
    border-top: solid 1px var(--systemBackground);

    .items {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .item {
        font-weight: 500;
        color: $textPrimary;

        .tag {
          font-weight: 600;
          padding: 4px;
          background-color: $systemBackground;
          color: $textPrimary;
          border-radius: 6px;
        }
      }
    }
  }
}