@import "../../../../../theme/variables.scss";

.item-list-dialog {
  height: 90vh;
  width: 80vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--systemBackground);

  .item-list-container {
    display: flex;
    gap: 8px;

    .side-menu-categories {
      display: flex;
      flex-direction: column;
      width: 21%;

      .side-menu-title {
        margin: 0;
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 700;
        margin-top: 12px;
      }
      .side-menu-wrapper {
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0;
        .category-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          height: 100%;
          max-height: 760px;
          padding: 5px 0 0;
          width: 100%;

          .category-opt {
            align-items: center;
            color: var(--textPrimary);
            display: flex;
            font-size: 13px;
            font-weight: 500;
            gap: 10px;
            padding: 10px 5px;
            transition: 0.2s;
            width: 90%;

            &:hover {
              background: var(--systemBackground);
              border-radius: 8px;
              cursor: pointer;
            }

            .category-option-title {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              font-size: 14px;
              font-weight: 600;
              color: var(--textPrimary);
            }
          }
        }
      }
    }

    .menu-categories-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-left: 12px;
      border-left: 1px solid var(--systemBackground);
      padding-left: 20px;
      width: 79%;
      .search-bar-container {
        display: flex;
        flex-direction: column;
      }
      .search-bar-container {
        width: 100%;
        margin-top: 12px;
        .p-input-icon-left {
          align-items: center;
          display: flex;
          .search-icon {
            display: flex;
            align-items: center;
            background: var(--systemBackground);
            padding: 0.65rem 1.2rem;
            padding-left: 16px;
            border: 0px solid #f5f8ff;
            appearance: none;
            border-radius: 8px 0px 0px 8px;
          }
          .p-inputtext {
            display: flex;
            justify-content: space-between;
            width: 300px;
            padding-left: 12px;
            border-radius: 0px 8px 8px 0px;
          }
        }
      }

      .categories-empty-results {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        color: var(--textPrimary);
        font-size: 12px;
        font-weight: 500;
        margin-top: 20px;
      }

      .categories-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .category-name {
          margin: 0;
          margin-top: 20px;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 700;
        }

        .items-container {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 20px;

          .item {
            display: flex;
            transition: 0.2s;
            flex-direction: column;
            height: 310px;
            width: 350px;
            padding: 16px 15px;
            gap: 8px;
            border-radius: 8px;
            border: $defaultBorder;
            box-shadow:
              0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1);

            &:hover {
              border: 1px solid var(--textTertiary);
              padding: 16px 15px;
              cursor: pointer;
              gap: 8px;
            }

            .image-container {
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 100%;
              height: 100%;
            }

            .text-container {
              flex-basis: 90px;
              height: 90px;
              display: flex;
              flex-direction: column;
              justify-content: start;
              gap: 10px;

              .item-title {
                margin: 0;
                color: var(--textPrimary);
                font-size: 14px;
                font-weight: 700;
              }

              .item-description {
                margin: 0;
                color: var(--textTertiary);
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}
