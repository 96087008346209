@import "../../../theme/variables.scss";
@import "../../../theme/animations.scss";

.movement {
  padding: 30px;

  .movement-container {
    background-color: $systemForeground;
    display: flex;
    flex-direction: column;
    border-radius: $buttonBorderRadius;
    box-shadow: $shadowBlue;

    .content {
      display: flex;
      flex-grow: 1;
      min-height: 400px;
      border-top: 1px solid var(--systemBackground);

      .source-selection {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 20px;
        padding: 20px 30px;
        border-right: 1px solid var(--systemBackground);
      }

      .operation {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1.5;
        padding: 20px 30px;
      }

      .title {
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 500;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      padding: 10px 30px;
      border-top: 1px solid var(--systemBackground);
    }
  }
}
