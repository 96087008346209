.title-type {
  display: flex;
  align-items: center;
  height: 100%;

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
}
