@import "../../../../theme/variables.scss";

.modal-manage-user {
  width: 90%;
  max-width: 600px;

  .p-dialog-header {
    display: flex;
    height: 80px;
    padding: 10px 20px 10px 30px;
    justify-content: space-between;
    align-items: center;

    .p-dialog-header-icons {
      display: flex;
      align-items: center;
      padding-top: 16px;
    }
    .modal-header {
      display: flex;
      justify-content: center;

      .modal-title-wrapper {
        color: var(--textPrimary);
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        height: 30px;
      }
    }
  }
  .p-dialog-content {
    padding: 0px 30px;
    border-top: solid 1px var(--systemBackground);
  }

  .form-body-wrapper {
    display: flex;
    flex-direction: column;

    .form-row {
      display: flex;
      width: 100%;
      margin: 7px;

      .input-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1 !important;
        gap: 5px;

        .modal-input-text {
          display: flex;
          flex-shrink: 0;
          width: 100%;
        }
      }
    }
  }
  .permissions-dropdown {
    display: flex;
  }

  .p-inputgroup-addon {
    border: none !important;
    background-color: var(--inputAddOnBackgroundColor);
    color: var(--inputAddOnTextColor);
    font-weight: 400;
  }
  .input-username {
    height: 40px !important;
    border-color: var(--inputBorderColor) !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .modal-user-field-before-button {
    background-color: #eaecf0 !important;
    padding: 13px 10px 14px 10px !important;
    position: relative;
    border-radius: 6px 0px 0px 6px;
    border: none;
    bottom: 33px;
    left: 1px;
  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .form-wrapper {
    padding: 30px 0px 10px 0px;

    .dcp-table-modal {
      table {
        padding: 30px 0px 0px;
        thead {
          height: 44px;
          th {
            display: table-cell;
            vertical-align: middle;
            padding: 0px 24px !important;
            background-color: $tableHeaderBackground;
            span {
              font-weight: 600;
              font-size: 14px;
              color: $textSecondary;
            }
          }
        }
        tbody {
          tr {
            background-color: $tableBodyBackground;
            td {
              padding: 16px 14px !important;
              font-weight: 400;
              font-size: 14px;
              color: $textSecondary;
            }

            &:hover {
              background-color: $tableRowHoverBackgroundColor;
            }
          }

          .table-actions {
            display: flex;
            align-items: center;
            gap: 5px;
            color: $textSecondary;

            .table-action-button {
              display: flex;
              width: 35px;
              height: 35px;
              padding: 0;
              justify-content: center;
              align-items: center;
              background-color: $tableActionButtonBackgroundColor;

              span {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .table-action-button-icon {
                color: $tableActionButtonIconColor;
                width: 18px !important;
                height: 18px !important;
              }

              &:hover {
                background-color: $tableActionButtonHoverBackgroundColor;
                .table-action-button-icon {
                  color: $tableActionButtonHoverIconColor;
                }
              }
            }
          }
        }
      }
    }

    .p-accordion-header-link {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      background: var(--systemForeground) !important;
      border-color: #ff0050 !important;
      color: var(--textPrimary);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      .header {
        justify-content: space-between;
        flex-grow: 1;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 8px;

        .title-header {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          color: var(--error-500, #f9485b);
        }
        .toggle-header {
          display: flex;
        }
      }
    }

    .p-accordion
      .p-accordion-header:not(.p-disabled).p-highlight:hover
      .p-accordion-header-link {
      background: #ffffff;
      border-color: #ff0050;
    }

    .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
      box-shadow: none;
    }

    .p-accordion-content {
      border-left: 1px solid #f9485b;
      border-right: 1px solid #f9485b;
      border-bottom: 1px solid #f9485b;
      padding: 20px !important;
    }

    .danger-zone-body-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .action-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        gap: 11px;

        .body-wrapper {
          display: flex;
          flex-direction: column;
          width: 336px;
          .title {
            color: var(--textTertiary);
            font-size: 14px;
            font-weight: 500;
          }
          .description {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--textPrimary);
          }
        }

        .button-wrapper {
          .user-btn-delete {
            border-radius: 8px;
            background: var(--dangerButtonBackgroundColor);
            color: var(--error-500, #f9485b);
            transition: 0.2s;

            &:hover {
              background: var(--error-50, #f6bfc3);
            }
          }
        }
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    padding: 0px 30px;
    align-items: center;
    height: 80px;
    border-top: solid 1px var(--systemBackground);

    .modal-footer {
      display: flex !important;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .dcp-btn-wrapper-newpass {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .new-pass-icon {
        color: #4146ff !important;
        margin-right: 10px;
      }

      .dcp-btn-wrapper-manage-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
