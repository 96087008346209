@import "../../../../../../../../theme/variables.scss";

.list-order-container {
  .list-order-table {
    color: var(--textPrimary);
    transition: all 0.3s;
    .repack-count-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .progress-bar-repack {
        height: 8px;
        width: 100%;
      }
      .days-repack-count {
        display: flex;
        font-size: 12px;
      }
    }

    .status-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #000000;
      padding: 5px 10px;
      border-radius: 15px;
    }

    .location-wrapper {
      display: flex;
      width: 40px;
      height: 40px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 30px;
      padding: 5px;
      background-color: var(--systemBackground);
      .location-title {
        font-size: 15px;
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
}
