@import "../../../../theme//variables.scss";

.setup-print-input-fields-dialog {
  width: 600px;
  height: 483px;

  .p-dialog-header {
    border-bottom: 1px solid var(--systemBackground) !important;

    .entry-dialog-header-title {
      color: var(--textPrimary);
      font-size: 20px;
      padding: 10px;
    }
  }

  .entry-dialog-content {
    padding-top: 20px;
    margin-left: 10px;
  }

  .p-dialog-footer {
    border-top: 1px solid var(--systemBackground) !important;

    .entry-dialog-footer {
      .actions {
        padding-top: 20px;
      }
    }
  }
}

.column-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;

  .column-actions {
    display: flex;
    height: 30px;
    align-items: center;

    .icon-wrapper {
      display: flex;
      justify-content: center;
      padding: 10px;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        padding: 10px;
        border-radius: 8px;
        background: #f5f5fd;
      }
    }
  }
}
