@import "../../../../theme/variables.scss";

.text-config-menu {
  height: 100%;
  width: 0;
  background: white;
  transition: width 0.3s ease;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  position: absolute;
  top: 0;
  right: 0;
  z-index: 2000;

  &.open {
    width: 300px;
  }

  .content {
    width: 300px;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .actions {
      justify-content: flex-end;
    }
  }
}
