@import "../../../../theme/variables.scss";

.page-body-gpt-ai {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .settings-gpt-ai {
    background-color: var(--systemBackground);
    padding: 34px;
    height: 100%;
    width: 100%;

    .container {
      display: flex;
      height: 100%;
      flex-direction: column;

      .header {
        height: 60px;
        padding: 18px 30px;
        border-bottom: 1px solid var(--systemBackground);
        border-radius: $inputBorderRadius $inputBorderRadius 0 0;
        background: $systemForeground;

        h3 {
          margin: 0;
          color: var(--textPrimary);
        }
      }

      .settings-content {
        height: 100%;
        display: flex;

        .settings-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          .container-area {
            background: $systemForeground;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            padding: 30px 30px;

            .group-container {
              width: 100%;
              max-width: 790px;
              height: 100%;

              .gpt-ai-setting-description {
                display: flex;
                align-self: start;
                font-size: 14px;
                padding-bottom: 20px;
                font-weight: 500;
                color: var(--textPrimary);
                margin: 0px;
              }

              .subgroup-gpt-ai-container {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                padding: 30px;
                width: 100%;
                background-color: var(--systemForeground);
                border: 1px solid var(--systemBackground);
                border-radius: 8px;

                .storage-warning {
                  display: flex;
                  width: 100%;
                  padding-bottom: 20px;
                  .custom-message,
                  .p-inline-message.p-inline-message-warn {
                    width: 100%;
                    position: relative;
                    border-radius: 4px;
                    background-color: #fde9eb !important;
                    color: #e91f35;
                    padding: 8px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }

                  .message-content {
                    display: flex;
                    align-items: center;
                    width: 100%;
                  }

                  .icon {
                    font-size: 1.5rem;
                    margin-right: 8px;
                    color: #d32f2f;
                  }

                  .message-text {
                    flex-grow: 1;
                    font-size: 14px;
                    color: #d32f2f;
                  }

                  .close-button {
                    background: none;
                    border: none;
                    color: #d32f2f;
                    cursor: pointer;
                    font-size: 10px;
                  }

                  .close-button .pi-times {
                    font-size: 10px;
                  }
                }

                .storage-info-title {
                  font-size: 1.5rem;
                  padding-bottom: 20px;
                  font-weight: 600;
                  color: var(--textPrimary);
                  margin: 0px;
                }

                .storage-data-wrapper {
                  display: flex;
                  gap: 8px;
                  width: 100%;
                  flex-direction: column;

                  .info {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;

                    .info-used {
                      display: flex;
                      font-size: 16px;
                      font-weight: 600;
                      flex-grow: 1;
                      color: var(--textPrimary);
                    }
                    .info-hired {
                      display: flex;
                      color: var(--textPrimary);
                      font-size: 14px;
                    }
                  }

                  .storage-line-wrapper {
                    display: flex;

                    .progression-storage-bar {
                      width: 100%;
                      border-radius: 4px;
                      background-color: var(--systemBackground);

                      .progression-storage-bar-filled {
                        height: 10px;
                        display: flex;
                        border-radius: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
