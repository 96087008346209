@import "../../../../theme/variables.scss";

.user-new-password-dialog {
  display: flex;
  width: 600px;
  padding: 24px;
  flex-direction: column;
  gap: 20px;
  background: $systemForeground;
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  .p-dialog-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0;

    .user-new-password-dialog-header-icon-container {
      display: flex;
      width: 48px;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      border: 8px solid #fff6e5;
      background: #ffefcf;
    }
  }
  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 !important;

    .user-new-password-dialog-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
    }
    .user-new-password-dialog-message {
      color: #667085;
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding-top: 8px;
      padding-bottom: 24px;
      margin: 0;
    }
    .p-inputgroup-addon {
      border: none;
    }
  }
  .p-dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 0;
    padding-top: 12px;

    button {
      margin: 0;
    }
  }
}
