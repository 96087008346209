@import "../../../../theme/variables.scss";

.database-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 65px);
  .database-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .database-new {
      display: flex;
      width: 100%;
      height: 100%;
      background: #eaecf5;

      .database-content {
        display: flex;
        justify-content: right;
        flex: 1;

        .form-body {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          background-color: var(--systemBackground);
          overflow: auto;

          .database-header {
            width: 100%;
            padding: 18px;
            display: flex;
            justify-content: space-between;

            .header-database-manage-wrapper {
              display: flex;
              gap: 15px;
              .back-button {
                display: flex;
                gap: 8px;
                border-radius: 20px;
                align-items: center;
                background: var(--primaryButtonBackgroundColor);
                padding: 10px 16px;

                span {
                  display: flex;
                  align-items: center;
                }
              }
              .back-button:hover {
                cursor: pointer;
                background-color: var(--systemForeground);
              }
              .visualize-database-button {
                display: flex;
                gap: 8px;
                border-radius: 20px;
                align-items: center;
                background: var(--primaryButtonBackgroundColor);
                padding: 10px 16px;

                span {
                  display: flex;
                  align-items: center;
                }
                .preview-label {
                  transition: 0.2s;
                  font-weight: 600;
                  color: var(--sideMenuLabelColor);
                }
              }
              .visualize-database-button:hover {
                cursor: pointer;
                background-color: var(--systemForeground);
              }

              .preview-button {
                display: flex;
                gap: 8px;
                border-radius: 20px;
                align-items: center;
                background: var(--primaryButtonBackgroundColor);
                padding: 10px 16px;

                span {
                  display: flex;
                  align-items: center;
                }

                .preview-label {
                  transition: 0.2s;
                  font-weight: 600;
                  color: var(--sideMenuLabelColor);
                }
              }
              .preview-button:hover {
                cursor: pointer;
                background-color: var(--systemForeground);
              }
            }

            .options-buttons {
              display: flex;
              gap: 20px;

              .clear-button {
                color: var(--textPrimary) !important;
              }
            }
          }
          .form-wrapper {
            display: flex;
            flex-direction: column;
            gap: 23px;
            height: fit-content;
            width: 600px;
            margin-top: 25px;
            margin-bottom: 25px;
            background: var(--systemForeground);
            border-radius: 10px;

            .form-header {
              font-size: 20px;
              font-weight: 500;
              color: var(--textPrimary);
              display: flex;
              align-items: flex-start;
              padding: 20px 40px 20px 40px;
              width: 600px;
              border-bottom: 1px solid var(--systemBackground);
              transition: 0.2s;

              .form-title {
                background-color: var(--systemBackground);
                font-size: 1.5rem;
                color: $textPrimary;
                border: 1px solid var(--systemBackground);
              }

              .p-invalid {
                border: 1px solid red;
              }

              .form-title:hover {
                border: 1px solid #464e5f;
              }

              ::placeholder {
                color: $textPrimary;
              }
            }
            .form-model-body {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;

              .form-demo-wrapper {
                display: flex;
                width: fit-content;
                align-items: center;
                padding: 10px 35px 10px 35px;
                .form-demo {
                  display: flex;
                  flex-wrap: wrap;
                  align-content: center;
                  flex-direction: column;
                  align-items: center;
                  padding: 30px 20px;
                  width: 100%;
                  height: 278px;
                  border: 2px dashed var(--dashboardEmptyCardBorderColor);
                  border-radius: 10px;

                  .form-example-body {
                    display: flex;
                    text-align: center;
                    padding: 20px 5px 0px;
                    font-size: 16px;
                    color: var(--textTertiary);
                  }
                }
              }

              // Form generated
              .form-generated-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                margin-bottom: 45px;
                border-radius: 20px;
                padding: 0px 20px 0px 20px;

                .blocked-form-field-wrapper {
                  display: flex;
                  flex-direction: column;
                  margin: 10px 0px 10px 0px;
                  gap: 8px;
                  width: 100%;
                  padding: 20px 20px 20px 20px;
                  border-width: 10px;
                  border-radius: 10px;
                  border: 1px solid var(--databaseFieldsBackgroundColor);
                  background: var(--systemBackground);
                  transition: 0.2s;

                  .header-field {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .field-label {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      font-weight: 500;
                      font-size: 17px;
                      color: #344054;
                      .icon-field-identifier {
                        margin-right: 17px;
                      }
                      .icon-field-identifier:hover {
                        display: flex;
                      }
                      .icon-label {
                        display: flex;
                        margin-right: 15px;
                        align-content: center;
                      }
                      .field-title {
                        width: 100%;
                        color: var(--textPrimary);
                        background: var(--systemForeground);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        transition: 0.2s;
                        border: 1px solid var(--databaseFieldsBackgroundColor);
                        padding-left: 5px;
                      }

                      .field-title:hover {
                        border: 1px solid #464e5f;
                      }
                    }

                    .field-manage-buttons {
                      display: flex;
                      align-items: center;
                      gap: 6px;

                      .edit-field-button {
                        cursor: pointer;
                        padding: 5px 0px 5px 0px;
                        transition: 0.2s;
                      }

                      .edit-field-button:hover {
                        filter: brightness(0.5);
                      }

                      .settings-button {
                        .settings-field-button {
                          cursor: pointer;
                          padding: 5px 0px 5px 0px;
                          transition: 0.2s;
                        }

                        .settings-field-button:hover {
                          filter: brightness(0.5);
                        }
                      }
                    }
                  }

                  .field-description {
                    padding-left: 48px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #667085;
                  }

                  // fields style
                  .form-field-text {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                  }

                  .form-field-longText {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                  }

                  .p-dropdown .form-field-dropdown {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                  }

                  .form-field-body {
                    width: 100%;
                    padding-left: 47px;
                  }
                }

                .form-field-wrapper {
                  display: flex;
                  flex-direction: column;
                  margin: 10px 0px 10px 0px;
                  gap: 8px;
                  width: 100%;
                  padding: 20px 20px 20px 20px;
                  border-width: 10px;
                  border: 2px dashed var(--systemForeground);
                  background: var(--systemForeground);
                  border-radius: 10px;
                  transition: 0.2s;

                  .header-field {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    justify-content: space-between;

                    .field-label {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      font-weight: 500;
                      font-size: 17px;
                      color: var(--textPrimary);
                      .icon-field-identifier {
                        margin-right: 17px;
                      }
                      .icon-field-identifier:hover {
                        display: flex;
                      }
                      .icon-label {
                        display: flex;
                        margin-right: 15px;
                        align-content: center;
                      }
                      .field-title {
                        width: 100%;
                        color: var(--textPrimary);
                        background: none;
                        border: none;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        transition: 0.2s;
                        border: 1px solid var(--inputBorderColor);
                        padding-left: 5px;
                      }

                      .field-title:hover {
                        border: 1px solid #464e5f;
                      }
                    }

                    .field-manage-buttons {
                      display: flex;
                      align-items: center;
                      gap: 6px;

                      .edit-field-button {
                        cursor: pointer;
                        padding: 5px 0px 5px 0px;
                        transition: 0.2s;
                      }

                      .edit-field-button:hover {
                        filter: brightness(0.5);
                      }

                      .settings-button {
                        .settings-field-button {
                          cursor: pointer;
                          padding: 5px 0px 5px 0px;
                          transition: 0.2s;
                        }

                        .settings-field-button:hover {
                          filter: brightness(0.5);
                        }
                      }
                    }
                  }

                  .field-description {
                    padding-left: 48px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #667085;
                  }

                  // fields style
                  .form-field-text {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                    background-color: var(--inputBackgroundColor);
                  }

                  .form-field-longText {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                  }

                  .form-field-dropdown {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    max-width: 487px;
                    max-height: 40px;
                    margin: 5px 0px 5px 0px;
                  }

                  .form-field-body {
                    width: 100%;
                    padding-left: 47px;
                  }
                }

                .form-field-wrapper:hover {
                  background: var(--systemForeground);
                  border-radius: 10px;
                  border: 2px dashed var(--pink-blue-100, #2969d6);
                }
              }
            }
          }
        }

        // Available fields column
        .field-menu {
          background-color: var(--systemForeground);
          border-radius: 0 0 0 6px;
          padding: 30px 30px;
          width: 300px;
          min-width: 300px;
          display: flex;
          flex-direction: column;

          .field-types-title {
            font-weight: 700;
            font-size: 16px;
            color: $textPrimary;
            margin-bottom: 10px;
          }
          .field-types-description {
            font-size: $defaultFontSize;
            font-weight: 400;
            color: var(--textPrimary);
            margin-bottom: 18px;
          }

          .search-input {
            display: flex;
            height: 40px;
            width: 100%;
            max-width: 350px;
            padding: 10px 12px;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: var(--inputBackgroundColor);

            .icon-search {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .p-inputtext {
              flex-grow: 1;
              background: var(--inputBackgroundColor);
            }
          }

          .form-fields {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            padding: 5px 0px 0px 0px;
            max-height: 760px;
            height: 100%;

            .field-opt {
              display: flex;
              align-items: center;
              transition: 0.2s;
              gap: 10px;
              border: 1px solid var(--systemBackground);
              border-radius: 10px;
              background: var(--databaseFieldsBackgroundColor);
              padding: 15px 14px;
              width: 100%;
              font-weight: 500;
              font-size: 13px;
              color: var(--textPrimary);

              .icon-field {
                display: flex;
              }
            }
            .field-opt:hover {
              border: 1px solid var(--databaseFieldsBorderColorOnHover);
              background: var(--databaseFieldsBackgroundColorOnHover);
              box-shadow:
                0px 2px 4px -2px rgba(16, 24, 40, 0.06),
                0px 4px 8px -2px rgba(16, 24, 40, 0.1);
            }
          }

          @media screen and (max-height: 911px) {
            .form-fields {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              width: 100%;
              padding: 5px 0px 0px 0px;
              max-height: 760px;
              height: 75%;
              overflow: auto;

              .field-opt {
                display: flex;
                align-items: center;
                transition: 0.2s;
                gap: 10px;
                border: 1px solid var(--systemBackground);
                border-radius: 10px;
                background: var(--databaseFieldsBackgroundColor);
                padding: 15px 14px;
                width: 100%;
                font-weight: 500;
                font-size: 13px;
                color: var(--textPrimary);

                .icon-field {
                  display: flex;
                }
              }
              .field-opt:hover {
                border: 1px solid var(--databaseFieldsBorderColorOnHover);
                background: var(--databaseFieldsBackgroundColorOnHover);
                box-shadow:
                  0px 2px 4px -2px rgba(16, 24, 40, 0.06),
                  0px 4px 8px -2px rgba(16, 24, 40, 0.1);
              }
            }
          }
        }
      }
    }
  }
}
