@import "../../../../../theme/variables.scss";

.inventory-details-header {
  background: $systemForeground;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);

  .header-container {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-action {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .back-icon {
        cursor: pointer;
      }
    }

    .icon {
      padding: 4px;
      &:hover {
        cursor: pointer;
      }
    }

    .header-buttons {
      display: flex;
      gap: 10px;
    }

    h1 {
      color: var(--textPrimary);
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }

  .content-container {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto;
    display: flex;
    gap: 20px;

    p {
      padding: 0;
      margin: 0;
    }

    .label {
      color: var(--textTertiary);
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .text {
      color: var(--textPrimary);
      font-size: 16px;
      font-weight: 600;
    }

    #first-section {
      display: flex;
      flex-direction: column;
      min-width: 570px;
      width: 100%;
      gap: 10px;

      .info-wrapper {
        display: flex;
        gap: 10px;

        .field {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      .area-wrapper {
        display: flex;
        flex-direction: column;

        .areas {
          display: flex;
          flex: 1;
          gap: 8px;

          .area {
            background: #d5d9eb;
            padding: 0 4px;
            border-radius: 3px;

            color: var(--gray-700, #344054);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .time-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .row {
          display: flex;
          gap: 10px;

          .time {
            flex: 1;
            .content {
              display: flex;
              gap: 8px;
            }
          }
        }
      }

      .location-wrapper {
        display: flex;
        flex-direction: column;

        .bar-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .bar {
            height: 16px;
            width: 340px;
            display: flex;
            border-radius: 8px;
            overflow: hidden;
            background: var(--systemBackground);

            span {
              color: white;
              font-size: 12px;
              font-weight: 500;
            }

            .section {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .success {
              background: #03ceb7;
            }

            .audit {
              background: #3b82f6;
            }

            .error {
              background: #e91f35;
            }

            .unprocessed {
              background: #e9981f;
            }
          }
        }
      }
    }

    #second-section {
      display: flex;
      flex-direction: column;
      // max-width: 270px;
      width: 100%;
      gap: 10px;

      .type-container {
        display: flex;
        flex-direction: column;

        .types {
          display: flex;
          gap: 10px;
          .type {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .drones-container {
        display: flex;
        flex-direction: column;

        .drones {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .drone {
            background: #d5d9eb;
            border-radius: 3px;
            padding: 0 4px;

            color: var(--gray-700, #344054);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .read-type-container {
        .read-type {
          display: flex;
          align-items: center;
          gap: 8px;

          .label {
            padding: 0;
          }
        }
      }
    }

    #third-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .resposibles-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .responsible {
          display: flex;
          gap: 10px;
          align-items: center;

          .p-avatar {
            height: 32px;
            width: 32px;
          }

          .info {
            display: flex;
            flex-direction: column;

            .text {
              font-size: 14px;
            }

            .label {
              font-size: 12px;
              padding-bottom: 4px;
            }
          }
        }
      }
    }

    #fourth-section {
      width: 100%;
      display: flex;
      flex-direction: column;

      .inventory-status-badge {
        margin-bottom: -10px;
      }

      svg {
        fill: transparent;
      }

      .svg-container {
        position: relative;
        align-items: flex-end;
        justify-content: center;
        width: 240px;
        height: 137px;
        margin-top: auto;

        .content {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 3px;

          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          .label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            padding: 0;
          }

          .value {
            color: var(--textTertiary);
            font-size: 30px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.inventory-status-control {
  display: flex;
  gap: 10px;

  .status-button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: var(--red-50);
    border-radius: 8px;
    color: var(--red-500);
    cursor: pointer;
  }
}
