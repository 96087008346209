@import "../../../theme/variables.scss";

.subgroup-container {
  color: var(--textPrimary);
  margin-top: 20px;

  .settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    p {
      font-size: 14px;
      font-weight: 400;
      color: var(--textPrimary);
      margin: 0;
      padding-bottom: 20px;
    }
    .form-subgroup {
      display: flex;
      flex-direction: column;

      .setting {
        height: 66px;
      }
    }

    .new-color-btn {
      width: fit-content;
      margin-top: 15px;
    }

    .icon-delete-field-input {
      display: flex;
      flex: 0 0;
      justify-content: center;
      margin-top: 12px;
      cursor: pointer;
    }
  }
}
