@import "../../../../theme/variables.scss";

.database-new-entry-edit {
  .p-dialog-footer {
    padding: 30px 40px;
    border-top: 1px solid var(--systemBackground);
    .finish-btn {
      margin: 0;
    }
  }

  .p-dialog-header {
    padding: 30px 40px;
    border-bottom: var(--default-border);

    .database-name {
      color: var(--textPrimary);
      font-size: 20px;
      font-weight: 600;
    }
  }

  .radio-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .radio-option-preview {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .fields-fixed-container {
    padding: 0 40px;
    overflow-y: auto;

    .input-wrapper {
      margin: 20px 0;

      .label {
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          color: var(--textPrimary);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;

          .help-icon {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
