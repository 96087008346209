@import "../../../../theme/variables.scss";

.inventory-list {
  display: flex;
  flex-direction: column;
  background: $systemForeground;
  border-radius: $inputBorderRadius;
  margin: 30px;
  border: solid 1px $painelBorderColor;

  .button-actions {
    display: flex;
    gap: 10px;

    .status-filter {
      background-color: var(--systemBackground);
      border: 0px solid;
    }

    .download-btn {
      padding: 10px;
    }
  }

  .datatable-container {
    height: 100%;

    .p-paginator {
      background: none;
      border: none;
    }
  }

  .status {
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
    }
  }

  .cell-text {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .actions {
    .p-button {
      padding: 16px 10px;

      .action-edit,
      .action-delete {
        color: var(--tableIconColor) !important;
      }
    }
  }

  .p-avatar {
    background: #f5f5fd;

    height: 32px;
    width: 32px;

    .p-avatar-text {
      color: var(--primary-600, #4146ff);
      font-size: 12px;
      font-weight: 500;
    }
  }
}
