@import "../../../../theme/variables.scss";

.datalake-dialog {
  width: 620px;
  .new-datalake-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 600px;
    height: 301px;

    .create-datalake-description {
      display: flex;
      align-items: center;
      color: var(--textPrimary);
      font-size: 16px;
      font-weight: 500;
    }

    .datalake-buttons-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 23px;

      .static-data-wrapper {
        display: flex;
        width: 190px;
        height: 180px;
        .internal-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 2px solid var(--systemBackground);
          padding: 15px;
          border-radius: 10px;
          .internal-container-title {
            display: flex;
            font-size: 14px;
            font-weight: 600;
            color: var(--textPrimary);
          }

          .internal-container-description {
            color: var(--textTertiary);
          }

          .datalake-icon {
            flex-grow: 1;
            display: flex;
            justify-content: end;
            padding-top: 20px;

            .database-icon {
              display: flex;
              height: 60px;
              width: 60px;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              background-color: var(--systemBackground);
              padding: 5px;
            }

            .report-icon {
              display: flex;
              height: 60px;
              width: 60px;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              background-color: var(--systemBackground);
              padding: 5px;
            }
          }
        }
        .internal-container:hover {
          border: 2px solid #4146ff;
          transition: 0.2s;
          cursor: pointer;
        }
      }
      .dynamic-data-wrapper {
        display: flex;
        width: 190px;
        height: 180px;
        .internal-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 2px solid var(--systemBackground);
          padding: 15px;
          border-radius: 10px;
        }
      }
    }
  }

  .database-selected-option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 301px;

    .datalake-selected-form-wrapper {
      display: flex;
      padding: 0px 30px;

      .p-multiselect {
        border: none !important;
        border-radius: 8px !important;
        background-color: var(--systemBackground) !important;
        max-width: 560px;
      }
    }
  }
  .report-selected-option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: 550px;
    height: 391px;

    .datalake-selected-form-wrapper {
      display: flex;
      flex-grow: 1;
      padding: 0px 30px;

      .report-products-row-container {
        width: 100% !important;

        .data-source-input-container {
          width: 265px;
        }
      }

      .p-multiselect {
        border: none !important;
        border-radius: 8px !important;
        background-color: var(--systemBackground) !important;
        min-width: 550px;
      }
    }
  }

  .modal-footer-wrapper {
    display: flex;
    border-top: solid 1px var(--systemBackground);
    height: 80px;
    .modal-footer {
      display: flex !important;
      align-items: center;
      width: 100%;
      justify-content: end;
      background: var(--systemForeground);
      color: #344054;
      padding: 0px 30px;

      .dcp-btn-wrapper-manage-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
