@import "../../../../theme/variables.scss";

.inventory-settings-denied-access-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--systemBackground);
  color: var(--textPrimary);
  font-size: 24px;
}

.inventory-settings-wrapper {
  border-radius: $panelBorderRadius;
  border: solid 1px var(--painelBorderColor);
  margin: 34px;
  .inventory-settings {
    height: 100%;

    .form-row {
      // margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      height: 100%;
      flex-direction: column;

      .header {
        height: 60px;
        padding: 18px 30px;
        border-bottom: 1px solid var(--tableTrBorderColor);
        border-radius: $inputBorderRadius $inputBorderRadius 0 0;
        background: $systemForeground;

        h3 {
          margin: 0;
          color: var(--textPrimary);
        }
      }

      .settings-content {
        height: 100%;
        display: flex;

        .side-bar {
          height: 100%;
          width: 300px;
          padding: 18px 30px;
          border-radius: 0 0 0 $inputBorderRadius;
          background: var(--systemForeground);
          border-right: 1px solid var(--tableTrBorderColor);

          p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: var(--textSecondary);
          }

          ul {
            padding: 0;
            margin: 18px 0;
            li {
              text-decoration: none;
              list-style: none;
              margin-top: 15px;
              border-radius: $inputBorderRadius;
              padding: 8px 20px;
              font-weight: 500;
              color: var(--textPrimary);

              &.selected {
                background: var(--systemBackground);
              }

              &:hover {
                background: var(--systemBackground);
                cursor: pointer;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }

          .skeleton {
            margin-top: 15px;
          }
        }

        .settings-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          .container {
            background: $systemForeground;
            display: flex;
            align-items: center;
            overflow: auto;
            padding: 30px 30px;
          }

          .footer {
            height: 60px;
            flex-shrink: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 30px;
            border-top: 1px solid var(--systemBackground);
            border-radius: 0 0 $inputBorderRadius 0;
            background: $systemForeground;
          }
        }
      }
    }
  }
}
