.home-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 450px;
  background: var(--systemForeground);
  border: 1px dashed #98a2b3;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;

  .img-empty {
    box-shadow: none;
    .image-background {
      background: var(--systemForeground);
    }
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 1px;
      align-self: center;
      display: flex;
      text-align: center;
      color: var(--textPrimary);
    }

    .home-product-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 24px;

      .home-product-body {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        margin-top: 8px;
        color: var(--textPrimary);
      }

      .button-wrapper {
        display: flex;

        .product-btn {
          display: flex;
          width: fit-content;
          gap: 8px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
