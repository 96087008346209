.andon-errors {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--textSecondary);
    }
  }

  .error-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    .error-line {
      padding: 4px 10px;
      font-size: 1.2rem;
      color: white;
      margin-top: 8px;
      border-radius: 6px;

      &.severity-0 {
        background-color: #f5a623;
      }

      &.severity-1 {
        background-color: #d0021b;
      }
    }
  }
}
