@import "../../../../theme/variables.scss";

.area-map-list {
  height: 100%;
  padding: 30px;

  .table-wrapper {
    background-color: var(--systemForeground);
    border-radius: $panelBorderRadius;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    border: solid 1px var(--painelBorderColor);

    .datatable-container {
      flex-grow: 1;
      .p-datatable .p-datatable-thead > tr > th {
        text-align: left;
        padding: 12px 24px !important;
        border: 1px solid var(--tableTrBorderColor);
        border-width: 0 0 1px 0;
        font-weight: 500;
        color: var(--textPrimary);
        background: var(--tableHeaderBackground);
        transition: box-shadow 0.2s;
      }
    }
  }

  .color-column-template {
    display: flex;
    align-items: center;
    gap: 10px;

    .color-circle {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }

  .top-shelf-column-template {
    background-color: var(--gray-100);
    padding: 2px 8px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 16px;

    &.no {
      background: var(--blue-50);
      color: var(--blue-600);
    }
  }

  .column-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    .column-actions {
      display: flex;
      height: 30px;
      align-items: center;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        padding: 10px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          padding: 10px;
          border-radius: 8px;
          background: var(--systemBackground);
        }

        .icon-row {
          color: var(--tableIconColor) !important;
        }
      }
    }
  }

  .p-dialog-content {
    padding: 0;
  }

  .edit-dialog {
    display: flex;
    flex-direction: column;
    min-width: 600px;

    .form-container {
      padding: 20px;

      .color-picker {
        flex-grow: 0;
      }
    }

    .button-container {
      border-top: solid 1px var(--systemBackground);
      padding: 20px 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      .p-button {
        max-width: 120px;
      }
    }
  }

  .p-paginator-bottom {
    background: none;
  }

  .color-column-template {
    display: flex;
    align-items: center;
    gap: 10px;

    .color-circle {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }
}
