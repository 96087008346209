@import "../../theme/variables.scss";

.main-layout {
  display: flex;
  flex-direction: column;
  background-color: $systemBackground;
  width: 100%;

  .main-layout-splitter-panel-side-menu-container {
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: $systemBackground;
  }

  .main-layout-splitter-panel-content-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .body {
    display: flex;
    height: calc(100vh - 65px);

    .page-content {
      width: 100%;
      height: calc(100vh - 65px);
      display: flex;
      flex-direction: column;

      .page-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }

  // override prime loading screen
  .p-datatable-loading-overlay {
    background: var(--systemForeground) !important;
    opacity: 0.3;
  }
}

.p-splitter {
  background-color: $systemForeground;
}
