@import "../../../theme/variables.scss";

.setting-dashboard-timers-dialog {
  display: flex;
  width: 520px;
  max-width: 550px;
  padding: 24px;
  flex-direction: column;
  background: $systemForeground;
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  .p-dialog-header {
    display: flex;
    padding: 0;
    align-items: flex-start;
    align-self: stretch;

    .p-dialog-header-icons {
      display: none;
    }

    .modal-setting-timer-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .modal-title-wrapper {
        width: 100%;
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
      }

      .setting-timer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 100%;

        .form-setting-timer {
          padding-bottom: 0px;
          gap: 12px;
          .input-auto-refresh {
            width: 100%;
            height: 40px;
          }
          .input-autoplay {
            width: 100%;
            height: 40px;
          }
        }
        .p-button {
          height: 40px;
          gap: 8px;
          .p-button-icon {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .card.flex.justify-content-center {
    margin: 0px -7px 0.2rem 1.5rem !important;
  }

  .p-dialog-footer {
    padding: 0;
  }
}
