@import "../../../../theme/variables.scss";

.order-monitor-details {
  width: 100% !important;
  max-width: 1173px;

  .p-dialog-content {
    padding: 0px !important;
  }

  .order-monitor-details-header {
    padding: 20px;
    border-bottom: 1px solid var(--systemBackground);
  }

  .p-tabview-nav-container {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    border-bottom: 1px solid var(--systemBackground);

    .report-track-button-wrapper {
      display: flex;
    }

    .p-tabview-nav-content {
      width: 100%;
    }
  }

  .p-tabview .p-tabview-nav {
    background-color: var(--systemForeground);
    border: none !important;
  }

  .tab-view-container {
    background-color: var(--systemForeground);
    width: 100%;

    .p-tabview-panels {
      padding: 0px !important;
      background-color: var(--systemForeground);
    }

    .tab-header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      .tab-panel-title {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: var(--textPrimary) !important;
      }
    }

    .tab-report-track-number-header {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      gap: 8px;
      align-items: center;
      padding: 0px 20px;
    }

    .order-tab-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .order-left-side-wrapper {
        display: flex;
        padding: 30px;
        flex-direction: column;
        gap: 24px;
        width: 757px;

        .info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          align-items: center;
          .info-grid-item {
            display: flex;
            flex-direction: column;

            .track-number-link {
              color: var(--primary);
              padding: 8px 0px;
              cursor: pointer;
            }
          }

          .info-grid-item label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          .info-grid-item span {
            font-size: 16px;
            color: var(--textPrimary);
            font-weight: 600;
            padding: 8px 0px;
            border-radius: 4px;
          }

          .info-grid-item .carrier-dropdown {
            padding: 5px 10px;
          }

          .processing-status-finished {
            border: 1px solid var(--green-100);
            background-color: var(--green-50);
            padding: 3px 10px 3px 10px;
            border-radius: 6px;
            color: #03ceb7 !important;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .processing-status-pending,
          .processing-status-loaded,
          .processing-status-packing,
          .processing-status-report-pending,
          .processing-status-carrier-definition,
          .processing-status-print-pending {
            border: 1px solid var(--gray-100);
            background-color: var(--gray-50);
            padding: 3px 10px 3px 10px;
            border-radius: 6px;
            color: var(--gray-600);
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .processing-status-carrier-error,
          .processing-status-carrier-integration,
          .processing-status-error-report {
            border: 1px solid var(--red-100);
            background-color: var(--red-50);
            padding: 3px 10px 3px 10px;
            border-radius: 6px;
            color: var(--red-600);
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        .log-process-wrapper {
          label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }
          .span-logs {
            color: var(--textPrimary);
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            background-color: var(--inputBackgroundColor);
            padding: 16px;
            border-radius: 8px;
            overflow: auto;
            max-height: 400px;
            min-height: 300px;
          }
        }
      }

      .timeline-container {
        width: 400px;
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        padding: 16px;
        border-left: 2px solid var(--systemBackground);
      }

      .timeline {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .timeline-item {
        display: flex;
        margin-bottom: 5px;
      }

      .timeline-icon {
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-left: 18px;

        .timeline-step-done {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: var(--systemBackground);
          color: var(--green-100);
          padding: 8px;
          border-radius: 50%;
        }

        .timeline-blank-step {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: var(--systemBackground);
          color: var(--textPrimary);
          padding: 8px;
          border-radius: 50%;

          .timeline-step-number {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }

        .timeline-error-step {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: #f63d68;
          padding: 8px;
          border-radius: 50%;
        }
      }

      .timeline-separator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 37px;
        width: 2px;
        background-color: var(--systemBackground);
        height: 50px;
      }

      .timeline-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 18px;
        padding: 8px 8px;
        border: 0px solid #ccc;
        border-radius: 4px;
        color: var(--textPrimary);
        &.strong {
          font-weight: bold;
          font-size: 14px;
        }

        &.timeline-content p {
          padding: 0px;
          margin: 0px;
          font-size: 12px;
        }

        .description {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }

    .monitor-pictures-view {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px 30px;

      .row {
        display: flex;
        gap: 20px;
      }
      .loading {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }

      .images-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .user-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image-wrapper {
            padding: 10px 6px 6px 6px;
            background: var(--systemBackground);
            border-radius: 6px;
            width: 162px;
            height: 210px;

            .images-preview-thumbnail {
              height: 150px !important;
              width: 150px !important;
            }

            .icon {
              display: none;
            }

            &:hover {
              .icon {
                display: flex;
                align-items: center;
              }
            }

            .image-header {
              display: flex;
              gap: 10px;
              margin-bottom: 10px;
              min-height: 32px;

              .filename-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-grow: 1;

                .img-icon {
                  flex-basis: 20px;
                  width: 20px;
                }

                .filename {
                  flex-grow: 1;
                  margin: 0;
                  color: var(--textPrimary);
                  font-size: 12px;
                  font-weight: 500;
                  text-overflow: ellipsis;
                  flex-basis: 0;
                }
              }
            }

            .p-image-preview-container {
              border-radius: 6px;
              background-color: #d1d1d1;
              display: flex;
              justify-content: center;
            }

            img {
              height: 150px;
              border-radius: 6px;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid var(--systemBackground);
    gap: 16px;
    padding: 18px 18px 18px 0px !important;
  }
  .p-dialog-footer {
    flex-grow: 1;
    display: flex;
    gap: 16px;
    padding: 0px !important;
  }
}
