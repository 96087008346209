@import "../../../../../theme/variables.scss";
@import "../../../../../theme/animations.scss";


.command-modal-picking {
  margin: -20px;

  .content {
    display: flex;
    min-height: 100px;
    min-width: 100%;
    margin-top: 10px;
    flex-direction: row;
    flex-wrap: nowrap;

    .send-command {
      padding: 20px;
      min-height: 300px;
      height: 100%;
      width: 40%;
      .command-buttons{
        display: flex;
        gap: 5px;
      }
    }

    .view-response {
      padding: 20px;
      min-height: 300px;
      height: 100%;
      width: 60%;
      border-left: 1px solid var(--systemBackground);
      background-color: var(--rackExampleBackgroundColor);
      display: flex;
      gap: 30px;
      flex-direction: column;

      .title{
        font-size: 16px;
      }
      .html-response{
        font-size: 12px;
      }
    }
  }

  .footer {
    border-top: solid 1px var(--systemBackground);
    padding: 15px;

    .items {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .item {
        font-weight: 500;

        .tag {
          font-weight: 600;
          padding: 4px;
          background-color: var(--systemBackground);
          color: var(--textTertiary);
          border-radius: 6px;
        }
      }
    }
  }
}