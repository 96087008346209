@import "../../../../theme/variables.scss";

.rack-list-container {
  height: 100%;
  padding: 30px;

  .color-column-template {
    display: flex;
    align-items: center;
    gap: 10px;

    .color-circle {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }

  .table-wrapper {
    border: solid 1px var(--painelBorderColor);
    background-color: var(--systemForeground);
    border-radius: $panelBorderRadius;
    display: flex;
    flex-direction: column;

    .datatable-container {
      flex-grow: 1;
      .status-cell {
        display: flex;
        align-items: center;
        .status-identification {
          font-size: 14px;
          display: inline-flex;
          font-weight: 500;
          height: 22px;
          padding: 4px 0px;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          border-radius: 16px;
          gap: 3px;
          &.active {
            width: 56px;
            color: $tableStatusLabelActive;
            background: $tableStatusLabelBackgroundActive;
          }

          &.inactive {
            width: 68px;
            color: $tableStatusLabelInactive;
            background-color: $tableStatusLabelBackgroundInactive;
          }
        }

        .user-name {
          flex: 1 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wrap;
        }
      }

      .column-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        .column-actions {
          display: flex;
          height: 30px;
          align-items: center;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            padding: 10px;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
              padding: 10px;
              border-radius: 8px;
              background: #f5f5fd;
            }
          }
        }
      }
    }
  }

  .p-paginator-bottom {
    background: none;
  }
}
