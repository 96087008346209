@import "../../../../theme/variables.scss";

.PageHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--systemForeground);

  .left-side {
    display: flex;

    .return {
      display: flex;
      align-items: center;
      align-content: center;
      margin-right: 16px;

      .return-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;

      .title {
        color: $textPrimary;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
      .records-count {
        color: var(--tableHeaderTitle);
        padding: 2px;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }

  .right-side {
    display: flex;
    gap: 15px;
    .search-input-wrapper {
      border-radius: 8px;
      display: flex;
      background-color: var(--inputBackgroundColor);

      .search-input {
        display: flex;
        align-items: center;
        .search-icon {
          display: flex;
          margin-left: 8px;
        }
      }
    }
  }
}
