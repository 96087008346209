@import "../../../theme/variables.scss";
@import "../../../theme/animations.scss";

.transfer-branches-main-container {
  display: flex;
  height: 100%;
  color: var(--textPrimary);
  background: var(--blue-gray-50);
  flex-direction: column;

  .output-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
    gap: 10px;

    .container {
      width: 100%;
      height: 100%;
      background: $systemForeground;
      border-radius: 6px;
      background-color: $systemForeground;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        height: 60px;
        border-bottom: solid 1px var(--systemBackground);
        padding: 8px 15px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .title-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 5px;
          .header-message {
            .title {
              color: var(--textPrimary);
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
            }
          }

          .total-items-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 22px;

            .total-items-count {
              padding: 2px;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              color: var(--primary-600, #4146ff);
            }
          }
        }

        .options-wrapper {
          display: flex;
          gap: 20px;
          border-right: 1px solid var(--gray-250, #dee0e3);
          .search-input {
            display: flex;
            align-items: center;
            width: 220px;
            height: 40px;
            background: var(--inputBackgroundColor, #fff);
            border-radius: 8px;
            padding: 10px 14px;
          }

          .icon-options {
            display: flex;
            gap: 5px;
            margin-right: 20px;
            .btn-column {
              border: none;
              background: none;
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              transition: 0.2s;

              &:hover {
                border: none;
                padding: 10px;
                border-radius: 8px;
                background: #f5f5fd;
                box-shadow: none;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .style-address {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        width: fit-content;
        height: 32px;
        background-color: #f5f5fd;
        color: #4146ff;
        padding: 9px;
        border-radius: 8px;
      }

      .containerTables {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .tableLeft {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding: 20px 30px;
          border-right: solid 1px var(--systemBackground);

          .side-menu-title {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .tableLeft-title {
              display: flex;
              align-items: center;
              height: 24px;
              font-size: 1.1em;
              font-weight: 600;
            }
          }

          .output-settings-side-fields {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .inputText {
              width: 17vw;
              min-width: 200px;
            }

            .loading-fields-wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }
        }
        .ListItens {
          width: 100%;
          .title {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 1.1em;
            margin-left: 12px;
            height: 64px;
          }
          .p-column-title {
            color: var(--textPrimary);
          }

          .p-datatable-tbody {
            .quantity-column-number .p-inputnumber-input {
              background: #fff;
              padding: 9px;
            }
          }
        }
      }

      .outputFooter {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-direction: row-reverse;
        padding: 10px;
        border-top: solid 1px var(--systemBackground);

        .output-manage-data-buttons {
          display: flex;
          gap: 20px;

          .clear-data {
            color: #f9485b;
          }
          .clear-data:hover {
            background-color: #ffe8ea;
          }
        }
      }
    }
  }
}
