.sku-create-dialog {
  .p-dialog-header {
  }

  .p-dialog-content {
    min-width: 600px;
    padding: 0px;
    border-top: solid 1px #eaecf0;

    .form-row.content {
      padding: 30px 30px 8px 30px;
    }
  }

  .button-container {
    border-top: solid 1px #eaecf0;
    padding: 20px 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .p-button {
      max-width: 120px;
    }
  }
}
