.database-field-edit-dialog {
  .p-dialog-header {
    padding: 0px;
    .header-title {
      display: flex;
      justify-content: flex-start;
      padding: 20px 20px 20px 30px;
      .field-title {
        color: var(--textPrimary);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
      }
    }
    .p-dialog-header-icons {
      padding: 20px 20px 20px 30px;
    }
  }

  .p-dialog-content {
    padding: 0px;
    border-top: solid 1px var(--systemBackground);
    border-bottom: 1px solid var(--systemBackground);
    background-color: var(--systemForeground);

    .edit-field-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0px 0px 0px 30px;
      .field-settings {
        display: flex;
        flex-direction: column;
        width: 460px;
        padding-top: 20px;
        padding-right: 30px;
        align-items: flex-start;
        gap: 10px;
        .form-row.content {
          display: flex;
          flex-direction: column;
          gap: 5px !important;

          .field-settings-description {
            color: var(--textPrimary);
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
          }
          .input-switch-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 5px;
          }

          .new-item-radio {
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            font-size: 13px;

            .add-multiple-option {
              display: flex;
              align-items: center;
              .add-multiple-label {
                font-weight: 600;
                color: #4147ff;
                transition: 0.4s;
              }
              .add-multiple-label:hover {
                cursor: pointer;
                color: #a0a3ff;
              }
            }
            .add-one-by-one-option {
              display: flex;
              align-items: center;
              border: 1px solid #4147ff;
              border-radius: 5px;
              padding: 5px;
              transition: 0.4s;
              font-weight: 600;
              color: #4147ff;
              transition: 0.4s;
            }
            .add-one-by-one-option:hover {
              cursor: pointer;
              color: #a0a3ff;
              border: 1px solid #a0a3ff;
            }
          }

          .radio-options-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            overflow-y: auto;
            max-height: 190px;

            .radio-options-wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: space-between;
              margin-top: 10px;

              .radio-option {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                gap: 12px;
                .option-label {
                  display: flex;
                  width: 100%;
                  height: 38px;
                }

                .remove-option {
                  display: flex;
                  border: 2px solid #fff;
                  padding: 7px;
                  border-radius: 8px;
                  transition: 0.2s;
                  align-items: center;
                }

                .remove-option:hover {
                  border: 2px solid #4b5563;
                  border-radius: 8px;
                  cursor: pointer;
                  color: #a0a3ff;
                  padding: 7px;
                }
              }
            }
          }

          .one-by-one-options {
            margin-top: 12px;
          }

          .multiple-options {
            margin-top: 12px;
            height: 100px;
          }
        }
      }
      .field-preview {
        display: flex;
        flex-direction: column;
        padding: 50px 40px;
        width: 580px;
        height: 588px;
        align-items: center;
        background: var(--systemBackground);
        border-radius: 0px;
        .field-preview-text {
          align-items: center;
          display: flex;
          margin-bottom: 40px;

          .field-preview-title {
            margin-left: 10px;
            font-weight: 500;
            font-size: 20px;
          }
        }
        .generated-preview {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 40px 20px;
          gap: 43px;
          width: 100%;
          max-width: 540px;
          height: 376px;
          background: var(--systemForeground);
          border-radius: 14px;

          .generated-field-preview {
            display: flex;
            flex-direction: column;
            width: 100%;

            .header-field {
              display: flex;
              gap: 15px;
              align-items: center;
              font-weight: 500;
              font-size: 17px;
              margin-bottom: 10px;
              margin-left: 39px;
              color: var(--textPrimary);
            }

            .description-preview {
              padding-left: 39px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #667085;
            }
            .form-field-text {
              display: flex;
              height: 100%;
              width: 100%;
              max-width: 420px;
              max-height: 40px;
              margin: 5px 0px 5px 38px;
            }
            .form-field-longText {
              display: flex;
              height: 100%;
              width: 100%;
              max-width: 420px;
              max-height: 40px;
              margin: 5px 0px 5px 38px;
            }

            .form-field-body {
              width: 100%;
              padding-left: 40px;
            }
          }

          .radio-options-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-left: 40px;

            .radio-option-preview {
              display: flex;
              gap: 5px;

              .p-radiobutton {
                width: 20px;
                height: 20px;
              }
              .p-radiobutton .p-radiobutton-box.p-highlight {
                border-color: #000000;
                background: #000000;
                .p-radiobutton-icon {
                  width: 10px;
                  height: 10px;
                  transition-duration: 0.2s;
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .form-row-footer {
      width: 100%;
      display: flex;
      gap: 20px;
      height: 79px;
    }

    .p-dialog-header {
      display: flex;
      justify-content: center;
      padding: 20px 20px 25px 30px;
      border-bottom: 1px solid var(--gray-200, #eaecf0);
      .p-dialog-title {
        color: #344054;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  .button-container {
    border-top: solid 1px var(--systemBackground);
    padding: 20px 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .p-button {
      max-width: 120px;
    }
  }
}
