@import "../../../theme/variables.scss";
.occupation-map-component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: $inputBorderRadius;
  background: $systemForeground;
  box-shadow: $defaultBoxShadow;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
  }

  header {
    border-bottom: 1px solid var(--systemBackground);
    padding: 20px 30px;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 600;
        font-size: 1.1rem;
        color: var(--textPrimary);
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .area-selection {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--textPrimary);

        .p-button {
          padding: 4px;
        }

        .chevron-button {
          height: fit-content;
        }

        .area-name {
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .filters {
        display: flex;
        gap: 10px;
        height: 40px;
        align-items: center;

        .search-input {
          display: flex;
          align-items: center;
          background-color: var(--inputBackgroundColor);
          padding: 0px 12px;
          border-radius: 8px;

          .icon-search {
            display: flex;
            justify-content: space-between;
          }
          .p-inputtext {
            flex-grow: 1;
            background-color: var(--inputBackgroundColor);
          }
        }

        .p-multiselect,
        .p-multiselect-trigger,
        .p-multiselect-label-container {
          background-color: var(--inputBackgroundColor);
          border: none !important;
          border-radius: 8px;
        }
      }
    }
  }

  .layout {
    height: 100%;
  }
}
