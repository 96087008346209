.b2b-orders-with-error-status {
  .header {

    .total-items-count {
      color: rgba(90, 94, 236, 255);
    }
  }

  .body {
    max-height: 400px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 10px;

    .btn-area-contains {
      display: flex;
      justify-content: center;

      .btn-show-more {
        max-width: 150px;
      }
    }

    .order-status {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      font-weight: 600;

      .b2b-orders-with-error-status-field {
        border: 1px solid var(--red-100);
        background-color: var(--red-50);
        padding: 3px 10px 3px 10px;
        border-radius: 6px;
        color: var(--red-600);
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

  }
}