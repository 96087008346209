@import "../../../theme/variables.scss";

.auth-side-menu {
  flex: 1;
  display: flex;
  flex-direction: row;
  transition:
    width 300ms ease-in-out,
    opacity 300ms ease-in-out;
  width: var(--width);
  background-color: $systemForeground;

  .side-menu-container {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .p-panelmenu .p-panelmenu-content {
    background-color: $systemForeground;
    border: 0px !important;
  }

  .p-menuitem-link {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .p-panelmenu .p-panelmenu-header>a:focus {
    outline: none;
    box-shadow: none !important;
  }

  .p-menuitem-text {
    color: #4e5ba6;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1.55rem;
  }

  .p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
    display: flex;
    align-items: center;
    font-size: 9px;
    color: $textSecondary !important;
  }

  .dcp-panel-menu {
    min-width: 320px;

    .p-panelmenu-panel {
      .p-panelmenu-header-link {
        background-color: transparent !important;
        border: none !important;

        .dcp-menu-item-label {
          font-weight: 600 !important;
        }
      }
    }

    .dcp-menu-item-label {
      flex: 1;
      color: $textTertiary !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    .icon-container {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px 3.999px 4px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      &.dashboards {
        background-color: #ffebe3;
      }

      &.databases {
        background-color: #e1ebff;
      }
    }
  }
}