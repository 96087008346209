@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.dashboard {
  display: flex;
  padding-top: 47px;
  overflow: auto;
  height: 93vh;

  .dashboard-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1256px;
    margin: 0px auto;
    margin-bottom: 30px;
    gap: 36px;

    .user {
      display: flex;
      align-items: center;
      gap: 17px;
      flex-direction: row;
      flex-wrap: wrap;

      .user-avatar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 4px solid $systemForeground;
        background-color: $systemForeground;
        box-shadow:
          0px 12px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);

        .user-avatar {
          path {
            stroke: $primary;
          }
        }
      }

      .user-name,
      .welcome-message {
        font-weight: 700;
        font-size: 16px;
        color: $textPrimary;

        .welcome-message {
          font-weight: 500;
          font-size: 16px;
        }
      }

      .dashboard-search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        flex-grow: 2;

        .search-input {
          display: flex;
          height: 40px;
          width: 300px;
          padding: 10px 13px;
          align-items: center;
          border-radius: 8px;
          background: var(--systemForeground, #fff);

          .search-icon-wrapper {
            display: flex;

            svg {
              top: 17px;
              color: var(--textPrimary);
            }
          }

          .p-inputtext {
            display: flex;
            flex-grow: 1;
            color: var(--textPrimary);
            background: var(--systemForeground);
          }
        }
      }
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(41, 47, 76, 0.2);
      background-color: $systemForeground;
      min-height: 180px;
      min-width: 190px;
      padding: 15px;
      cursor: pointer;
      transition: scale 300ms;

      &:hover {
        scale: 1.1;
      }

      .btn-favorite {
        cursor: pointer;
        transition: 300ms;
        border: 0px;
        background: transparent;
        padding: 0px;
        height: 20px;

        path {
          fill: transparent;
          stroke: #f0aa23;
        }

        &.checked {
          path {
            fill: #f0aa23;
          }
        }

        &:hover {
          filter: brightness(1.3);
        }
      }

      .p-button:focus {
        border-radius: 12px;
      }

      &.product-card {
        &.product-almox {
          .product-card-background {
            path {
              fill: var(--dashboardWarehouseCardBackground);
            }
          }
        }

        &.product-qhse {
          .product-card-background {
            path {
              fill: #fff4c7;
            }
          }
        }

        &.product-ppe {
          .product-card-background {
            path {
              fill: #e0eaff;
            }
          }
        }

        &.product-sequence {
          .product-card-background {
            path {
              fill: var(--dashboardSequenceCardBackground);
            }
          }
        }

        .product-card-header {
          display: flex;

          .product-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .product-icon-container {
              margin-bottom: 2px;
            }

            .product-name {
              font-weight: 700;
              font-size: 14px;
              color: $textPrimary;
              margin-bottom: 4px;
            }

            .product-location {
              font-weight: 400;
              font-size: 12px;
              color: $textPrimary;
              z-index: 9999;
            }
          }
        }

        .product-card-content {
          flex-grow: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          z-index: 10;

          .product-users {
            display: flex;
            flex-direction: column;

            .users-profiles {
              display: flex;
              flex-grow: 1;
              justify-content: flex-end;

              .product-user {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                color: var(--dashboardProductUserIconLabelColor);
                background-color: var(--dashboardProductUserIconBackgroundColor);
                border-radius: 50%;
                cursor: pointer;
                border: 2px solid $systemForeground;

                &:not(:last-child) {
                  margin-right: -4px;
                }

                &:hover {
                  z-index: 100;
                  scale: 1.5;
                  transition: 300ms;
                  transform: translate(0px, -5px);
                }

                .user-identification {
                  font-weight: 500;
                  font-size: 12px;
                  color: var(--dashboardProductUserIconLabelColor);
                }

                .more-users {
                  font-weight: 500;
                  font-size: 12px;
                  color: var(--dashboardProductUserIconLabelColor);
                }
              }
            }

            .product-users-amount {
              font-weight: 400;
              font-size: 12px;
              color: $textPrimary;
            }
          }
        }

        .product-card-background {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 0;
        }
      }

      &.dashboard-card {
        .dashboard-card-header {
          display: flex;

          .dashboard-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 7.5px;

            .dashboard-card-actions {
              display: flex;
              justify-content: space-between;
              padding: 0px 10px 0px 0px;
            }

            .dashboard-name {
              font-weight: 700;
              font-size: 12px;
              color: $textPrimary;
              margin-bottom: 4px;
            }
          }
        }

        .dashboard-card-background {
          position: absolute;
          display: flex;
          bottom: 0;
          left: 0;
          z-index: 0;
        }

        .dashboard-card-icon {
          display: flex;
          z-index: 1;
          margin-top: 74px;
        }
      }

      .popup_menu_right {
        display: none;
      }

      &:hover .popup_menu_right {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      // Database-card
      &.database-card {
        flex: 1;
        padding: 15px;
        max-width: 180px;

        .database-card-header {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;

          .database-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 10px;
            background-color: var(--databaseCardBackgroundColor);
          }

          .settings-button {
            height: fit-content;

            .settings-field-button {
              cursor: pointer;
              padding: 8px 7px 0px 0px;
              transition: 0.2s;
            }

            .settings-field-button:hover {
              filter: brightness(0.5);
            }
          }
        }

        .database-card-content {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .database-name {
            color: $textPrimary;
            font-weight: 600;
            font-size: 14px;
          }

          .database-entries {
            color: $textPrimary;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }

    .products {
      display: flex;
      flex-direction: column;

      .products-header {
        display: flex;
        align-items: center;
        gap: 10px;

        .header-tittle {
          font-weight: 700;
          font-size: 16px;
          color: $textPrimary;
        }

        .add-product {
          path {
            stroke: $primary;
          }
        }
      }

      .content-divider {
        width: 100%;
        border: 1px solid var(--dashboardMainSeparatorColor);
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 8px;
      }

      .products-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 23px;

        .btn.add-item {
          cursor: pointer;
          display: flex;
          width: 190px;
          height: 180px;
          padding: 30px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%236468FFFF' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 10px;

          .icon-wrapper {
            display: flex;
            width: 50px;
            height: 50px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--primary-100, #eeeefd);
          }

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }

    .dashboards {
      display: flex;
      flex-direction: column;

      .dashboards-header {
        display: flex;
        align-items: center;
        gap: 10px;

        .header-tittle {
          font-weight: 700;
          font-size: 16px;
          color: $textPrimary;
        }

        .add-dashboard {
          path {
            stroke: $primary;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .content-divider {
        width: 100%;
        border: 1px solid var(--dashboardMainSeparatorColor);
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 8px;
      }

      .dashboards-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 23px;

        .btn.add-item {
          display: flex;
          cursor: pointer;
          width: 190px;
          height: 180px;
          padding: 30px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%236468FFFF' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 10px;

          .icon-wrapper {
            display: flex;
            width: 50px;
            height: 50px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--dashboardBlankCardBackgroundColor);
          }

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }

    .databases {
      display: flex;
      flex-direction: column;

      .databases-header {
        display: flex;
        align-items: center;
        gap: 10px;

        .header-tittle {
          font-weight: 700;
          font-size: 16px;
          color: $textPrimary;
        }

        .add-databases {
          path {
            stroke: $primary;
          }
        }
      }

      .content-divider {
        width: 100%;
        border: 1px solid var(--dashboardMainSeparatorColor);
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 8px;
      }

      .databases-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 23px;
        margin-bottom: 12px;

        .btn.add-item {
          display: flex;
          cursor: pointer;
          width: 190px;
          height: 180px;
          padding: 30px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%236468FFFF' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='37' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 10px;

          .icon-wrapper {
            display: flex;
            width: 50px;
            height: 50px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--dashboardBlankCardBackgroundColor);
          }

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }
  }

  @media (max-width: $mobileBreakPoint) {}
}