.file-upload-box {
  .upload-file-container {
    height: 287px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 3px dashed var(--databaseUploadSpaceColorOnHover);
    border-radius: 12px;
    margin: 20px 0 20px 0;
    transition: 0.2s;

    .upload-file-wrapper {
      display: flex;
      cursor: pointer;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      transition: 0.2s;

      .upload-file-img {
        height: 60px;
        width: 144px;
      }

      .upload-file-input {
        display: none;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        margin: 0;
      }

      .upload-input-description {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--textPrimary);
      }
    }

    .upload-file-wrapper:hover {
      border-radius: 12px;
      background-color: var(--databaseUploadSpaceColorOnHover);
    }
  }

  .upload-file-format {
    display: flex;
    gap: 1rem;
  }

  .upload-data-dialog-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 1rem 1rem 0;
  }
}
