@import "../../../../../src/theme/variables.scss";
.template-pattern-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--textPrimary);
}

.template-pattern-dialog {
  width: 500px;

  .template-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .pattern-field-container {
      display: flex;
      flex-direction: column;

      .pattern-field-title {
        display: flex;
        flex-direction: row;
      }

      .pattern-field {
        width: 100%;
        margin-top: 10px;
        max-width: 500px;
        z-index: 9999;
        position: absolute;

        .p-inputtextarea {
          width: 100%;
        }

        .mention-suggestion-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          z-index: 9999;
          height: 100%;

          .suggestion-names-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .save-pattern-btn {
        margin-left: auto;
        margin-top: 20px;
      }
    }
  }
}
