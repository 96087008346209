@import "../../../../theme/variables.scss";

.warehouse-map {
  display: flex;
  gap: 18px;
  background: var(--systemForeground);
  padding: 20px 30px;
  height: 100%;
  width: 100%;

  .grid-item {
    background: #d5d9eb;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    .rack-exception {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .rack {
        position: relative;
        z-index: 1;
        height: 100%;
        flex-grow: 1;
        border-right: 1px solid var(--tableTrBorderColor);
        border-bottom: 1px solid var(--tableTrBorderColor);

        &.exception {
          background: #fba6ba;
          border: 2px solid #fba6ba;
        }
      }

      p {
        position: absolute;
        top: 5;
        z-index: 2;
      }
    }

    p {
      margin: 0;
      color: var(--textPrimary);
      text-align: center;
      font-size: 12px;
      font-weight: 800;
      padding: 2px;
      pointer-events: none;
      z-index: 2;
    }
  }

  .options-menu {
    .p-overlaypanel-content {
      padding: 0;
      display: flex;
      .detail {
        width: 14px;
        background-color: #6468ff;
        border-radius: 8px 0 0 8px;
      }

      .icons {
        padding: 6px 10px;
        display: flex;
        gap: 10px;

        .p-button {
          padding: 8px;
          transition: none;
          border: none;

          &:hover {
            padding: 8px;
            border: none;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .react-transform-wrapper {
    width: 100%;
    height: 100%;
  }
  .react-transform-component {
    width: 100%;
    height: 100%;
  }

  .react-transform-component {
    background-size: 20px 20px;
    background-image: linear-gradient(
        to right,
        rgba(128, 128, 128, 0.15) 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgba(128, 128, 128, 0.15) 1px, transparent 1px);
  }
}

.warehouse-map-empty {
  height: 100%;
  width: 100%;
  background-color: var(--systemForeground);
}
