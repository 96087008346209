.components-example {
  max-width: 90%;
  margin: auto;

  .dcp-input-borderless {
    border: 0px !important;
  }

  .icons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
