@import "../../../../../theme/variables.scss";

.area-map-picking-count {
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: $panelBorderRadius;

  .area-map-picking-count-wrapper {
    display: flex;
    border-radius: $panelBorderRadius;
    flex: 1 1 0;
    flex-direction: column;
    border: solid 1px var(--painelBorderColor);
    .header {
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-bottom: 1px solid var(--systemBackground);
      background-color: $systemForeground;
      border-radius: $panelBorderRadius $panelBorderRadius 0 0;

      .area-configuration {
        display: flex;
        flex-direction: row;
        .area-selection {
          display: flex;
          justify-content: space-between;
          flex-grow: 1;

          .selector {
            display: flex;
            gap: 10px;
            align-items: center;

            .name {
              color: var(--textPrimary);
              font-size: 18px;
              font-weight: 600;
            }

            .icon {
              .right-setting-exists:hover {
                cursor: pointer;
              }
              .right-setting-does-not-exists:hover {
                cursor: not-allowed;
              }
            }
          }
        }

        .area-settings {
          display: flex;
          gap: 15px;
          height: 60px;
          .ai-button-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px;
          }
          .column-filter-wrapper {
            display: flex;
            justify-content: start;
            flex-direction: column;
            margin-left: 40px;
            gap: 5px;

            .column-filter-label {
              color: var(--textPrimary);
              font-size: 1rem;
              margin: 0;
              padding: 0;
            }
            .column-filter-switch {
              width: 40px;
              height: 25px;
            }
          }
          .date-range-wrapper {
            display: flex;
            justify-content: start;
            flex-direction: column;
            gap: 5px;
            .date-range-label {
              color: var(--textPrimary);
              font-size: 1rem;
              margin: 0;
              padding: 0;
            }
            .date-range-input {
              height: 35px;
              width: 200px;
            }
          }
        }
      }

      .area-map-sub-header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .square-container {
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
          gap: 10px;
          align-items: center;

          .rack-reliability {
            display: flex;
            flex-direction: row;
            gap: 30px;

            .rack-status-wrapper {
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;
              .rack-status-icon {
                display: flex;
                gap: 10px;
                align-items: center;
                flex-direction: row;
                width: 20px;
                height: 20px;
                border-radius: 4px;
              }
              .rack-status-value {
                display: flex;
                color: var(--textPrimary);
                font-size: 1rem;
                .rack-status-percentage {
                  color: var(--textPrimary);
                  font-size: 1rem;
                  font-weight: 600;
                }
              }
            }
          }

          .rack-tooltip {
            display: flex;
            gap: 10px;
            align-items: center;
            flex-direction: row;
            .rack-reliability-content {
              display: flex;
              align-items: center;
              color: var(--textPrimary);
            }
          }
        }
      }
    }

    .map-container {
      background-color: var(--systemForeground);
      flex-grow: 1;

      .map-container-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: var(--systemForeground);
      }
    }
  }

  .location-history-dialog {
    min-width: 200px;

    .history-dialog-content {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .history-location {
        display: flex;
        gap: 8px;
        .name {
          font-size: 1rem;
          margin: 0;
          padding: 2px 6px;
          border-radius: 6px;
        }
      }
    }
  }
}

// Rack reliability tooltip content
.picking-count-reliability-tooltip-wrapper {
  display: flex;
  flex-direction: column;

  .rack-tooltip-content {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .area-piking-map {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .line-item {
        display: flex;
        gap: 10px;
        .item-color {
          height: 22px;
          width: 22px;
          border-radius: 4px;

          &.color-1 {
            background-color: #641e16;
          }
          &.color-2 {
            background-color: #7b241c;
          }
          &.color-3 {
            background-color: #922b21;
          }
          &.color-4 {
            background-color: #a93226;
          }
          &.color-5 {
            background-color: #c0392b;
          }
          &.color-6 {
            background-color: #cd6155;
          }
          &.color-7 {
            background-color: #e6b0aa;
          }
          &.color-8 {
            background-color: #f2d7d5;
          }
          &.color-9 {
            background-color: #f9ebea;
          }
        }
      }
    }

    .rack-tooltip-title {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .rack-tooltip-description {
      font-size: 13px;
      max-width: 300px;
      text-align: justify;
    }
  }
}
