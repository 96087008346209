.historic-data-upload-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  .upload-history-data-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 1rem 1rem 0;
  }

  .historic-data-table-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 30px 40px;

    .historic-upload-table,
    .p-datatable {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      .user-cell {
        display: flex;
        align-items: center;
      }

      .column-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        .column-actions {
          display: flex;
          height: 30px;
          align-items: center;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            padding: 10px;
            transition: 0.2s;
            border-radius: 8px;
            cursor: pointer;
            .icon-edit {
              color: var(--tableIconColor) !important;
            }
            &:hover {
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
            }
          }
        }
      }
    }
  }
}
