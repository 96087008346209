@import "../../../../../theme/variables.scss";

.dashboard-grid-item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $systemForeground;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px $painelBorderColor;

  &.title {
    background: none;
    .header {
      p {
        visibility: hidden;
      }
    }
  }

  &:hover {
    .header {
      .icons {
        visibility: visible;
        cursor: pointer;
      }
    }
    .menu-btn,
    .btn-ia-analytic {
      display: block !important;
    }
  }

  .p-button {
    height: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    height: 39px;
    min-height: 24px;
    align-items: center;
    gap: 6px;

    .dashboard-grid-item-header-actions {
      display: flex;
      gap: 6px;
      visibility: hidden;
    }

    p {
      margin: 0;
      flex-grow: 1;
      color: $textSecondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .menu-btn,
    .btn-ia-analytic {
      display: none;
      padding: 0;
      align-items: center;
    }

    .menu-btn,
    .p-button-plain {
      display: flex;
      border-radius: 7px;
      width: -moz-fit-content;
      width: fit-content;
      padding: 2px;
      height: 24px;
      align-self: center;
    }

    .menu-option {
      .label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &:hover {
    .dashboard-grid-item-header-actions {
      visibility: visible;
    }
  }

  .item-content {
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    color: var(--textPrimary);

    p {
      color: var(--textSecondary);
    }

    .chart-load {
      padding: 30px;
      display: flex;
      height: 100%;
      gap: 20px;
      align-items: flex-end;
    }
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: #aab0c7;
  border-radius: 4px;
}

.react-grid-item > .react-resizable-handle {
  margin-right: 2px !important;
  margin-bottom: 2px !important;
}
