@import "../../../../../theme/variables.scss";

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.unprocessed-image-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 10px;
  width: 100%;
  height: 100%;


  .location-selection {
    display: flex;
    gap: 10px;

    .p-dropdown {
      min-width: 200px;
      flex-grow: 1;
    }
  }

  .file-drop-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;

    .selected-image-wrapper {
      display: flex;
      flex-direction: row;
      gap: 18px;
      width: 100%;
      height: 90%;
      justify-content: space-between;

      .left-arrow-wrapper {
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;

        .left-arrow-exists {
          height: 100%;
        }

        .left-arrow-does-not-exists {
          height: 100%;
          cursor: not-allowed;
        }
      }

      .image-preview-container {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .transform-weapper-content {
          display: flex;
          gap: 5px;
        }

        .image-preview-wrapper {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          background-color: var(--systemBackground) !important;
          border-radius: 8px !important;
          width: 100%;
          height: 100%;
          object-fit: contain;

          .image-preview {
            height: 100%;
            background-repeat: no-repeat !important;
            background-position: center !important;
          }
        }
      }

      .image-preview-loading-wrapper {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        background-color: var(--systemBackground) !important;
        border-radius: 8px !important;
        width: 100%;
        height: 670px;
      }

      .right-arrow-wrapper {
        display: flex !important;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        .right-arrow-exists {
          height: 100%;
        }

        .right-arrow-does-not-exists {
          height: 100%;
          cursor: not-allowed;
        }
      }

      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .image-counter {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 12px;
      font-weight: 500;
    }

    .multiple-files-msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      p {
        margin: 0;

        &.remove-all {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .no-image-warning {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      .no-image-text {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: var(--textPrimary);
      }
    }
  }
}