@import "../../../theme/variables.scss";

.mobile-list-panel {
  display: flex;
  width: 100%;

  .data-table-container {
    width: 100%;

    .pill {
      font-size: 14px;
      display: inline-flex;
      font-weight: 700;
      height: 22px;
      padding: 4px 0px;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      border-radius: 16px;
      gap: 3px;

      &.online {
        width: 56px;
        color: $tableStatusLabelActive;
        background: $tableStatusLabelBackgroundActive;
      }
      &.offline {
        width: 68px;
        color: $tableStatusLabelInactive;
        background-color: $tableStatusLabelBackgroundInactive;
      }
    }
  }
}
