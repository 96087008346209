.loading-indicator-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .loading-indicator,
  .loading-indicator:before,
  .loading-indicator:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loadingIndicatorAnimation 1.8s infinite ease-in-out;
    animation: loadingIndicatorAnimation 1.8s infinite ease-in-out;
  }
  .loading-indicator {
    color: var(--spinnerColor);
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &.loading-indicator-small {
      font-size: 3px;
    }
    &.loading-indicator-medium {
      font-size: 5px;
    }
    &.loading-indicator-large {
      font-size: 8px;
    }
  }
  .loading-indicator:before,
  .loading-indicator:after {
    content: "";
    position: absolute;
    top: 0;
  }
  .loading-indicator:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loading-indicator:after {
    left: 3.5em;
  }
}

@-webkit-keyframes loadingIndicatorAnimation {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes loadingIndicatorAnimation {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
