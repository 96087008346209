.floor-area-draw {
  position: absolute;
  width: 100%;
  padding: 20% 10%;

  .area-container {
    width: 100%;
    border-collapse: collapse;
    .row {
      width: 100%;
      .square {
        border: 10px solid var(--yellow-500);
        height: 100px;
        color: var(--textTertiary);
        width: fit-content;

        text-align: center;
        font-weight: 600;
        font-size: 1.25rem;
        vertical-align: bottom;
        padding-bottom: 5px;
      }
    }
  }

  .location-name {
    cursor: pointer;
  }
}
