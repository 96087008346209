.massive-print-dialog {
  width: 620px;
  height: 289px;

  .massive-print-selected-option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 190px;

    .massive-print-selected-form-wrapper {
      display: flex;
      padding: 0px 30px;

      .p-multiselect {
        border: none !important;
        border-radius: 8px !important;
        background-color: var(--systemBackground) !important;
        max-width: 560px;
      }
    }
  }
  .modal-footer-wrapper {
    display: flex;
    border-top: solid 1px var(--systemBackground);
    height: 80px;
    .modal-footer {
      display: flex !important;
      align-items: center;
      width: 100%;
      justify-content: end;
      background: var(--systemForeground);
      color: #344054;
      padding: 0px 30px;

      .dcp-btn-wrapper-manage-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
