@import "../../../../../../../../theme/variables.scss";

.open-inventory-accuracy {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .inventory-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    .label {
      margin: 0;
      color: var(--textPrimary);
      font-weight: 600;
    }

    .inv-row {
      display: flex;
      gap: 10px;
      max-height: 40px;
      height: 100%;
      position: relative;
      align-items: center;

      .percentage-label {
        color: $textSecondary;
        margin: 0;
      }

      .bar-container {
        position: relative;
        max-height: 40px;
        width: 100%;
        height: 100%;
        background: #f2f2f2;
        border-radius: 3px;

        .bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background: red;
          border-radius: 3px;
        }
      }
    }
  }
}
