@import "../../../../theme/variables.scss";

.area-map-history {
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: $panelBorderRadius;

  .area-map-history-wrapper {
    display: flex;
    border-radius: $panelBorderRadius;
    flex: 1 1 0;
    flex-direction: column;
    border: solid 1px var(--painelBorderColor);
    .header {
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-bottom: 1px solid var(--systemBackground);
      background-color: $systemForeground;
      border-radius: $panelBorderRadius $panelBorderRadius 0 0;

      .area-selection {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        .selector {
          display: flex;
          gap: 10px;
          align-items: center;

          .name {
            color: var(--textPrimary);
            font-size: 18px;
            font-weight: 600;
          }

          .icon {
            .right-setting-exists:hover {
              cursor: pointer;
            }
            .right-setting-does-not-exists:hover {
              cursor: not-allowed;
            }
          }
        }
      }

      .area-map-sub-header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .square-container {
          display: flex;
          justify-content: space-between;
          flex-grow: 1;
          gap: 10px;
          align-items: center;

          .rack-reliability {
            display: flex;
            flex-direction: row;
            gap: 30px;

            .rack-status-wrapper {
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;
              .rack-status-icon {
                display: flex;
                gap: 10px;
                align-items: center;
                flex-direction: row;
                width: 20px;
                height: 20px;
                border-radius: 4px;
              }
              .rack-status-value {
                display: flex;
                color: var(--textPrimary);
                font-size: 1rem;
                .rack-status-percentage {
                  color: var(--textPrimary);
                  font-size: 1rem;
                  font-weight: 600;
                }
              }
            }
          }

          .rack-tooltip {
            display: flex;
            gap: 10px;
            align-items: center;
            flex-direction: row;
            .rack-reliability-content {
              display: flex;
              align-items: center;
              color: var(--textPrimary);
            }
          }
        }
      }
    }

    .map-container {
      background-color: var(--systemForeground);
      flex-grow: 1;

      .map-container-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: var(--systemForeground);
      }
    }
  }

  .location-history-dialog {
    min-width: 200px;

    .history-dialog-content {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .history-location {
        display: flex;
        gap: 8px;
        .name {
          font-size: 1rem;
          margin: 0;
          padding: 2px 6px;
          border-radius: 6px;
        }
      }
    }
  }
}

// Rack reliability tooltip content
.rack-reliability-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  .rack-tooltip-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
    .rack-tooltip-title {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .rack-tooltip-subtitle-reliability {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      color: var(--textPrimary);
      &-reliable {
        color: #000000;
        font-weight: 600;
        border-radius: 3px;
        padding: 2px;
        width: fit-content;
        border: 1px solid #8cd8c0;
        background-color: #8cd8c0;
      }
      &-lessReliable {
        color: #000000;
        font-weight: 600;
        border-radius: 3px;
        padding: 2px;
        width: fit-content;
        border: 1px solid #ffe78a;
        background-color: #ffe78a;
      }
      &-unreliable {
        color: #000000;
        font-weight: 600;
        border-radius: 3px;
        padding: 2px;
        width: fit-content;
        border: 1px solid #fba6ba;
        background-color: #fba6ba;
      }
    }
    .rack-tooltip-description {
      font-size: 12px;
    }
  }
}
