.andon-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  p {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
  }
}
