.rack-example-dialog {
  .rack-example-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rack-example-dialog-content {
    width: 359px;
    height: 569px;
  }
}
