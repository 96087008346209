@import "../../../theme/variables.scss";

.p-button {
  height: 40px;
  gap: 8px;
  .p-button-icon {
    margin-right: 0.5rem;
  }

  .p-button-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.p-button-secondary {
  .p-button-label {
    font-weight: 600;
  }
}

.p-button.dcp-button-secondary {
  color: $iconDefaultColor !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  &:hover {
    background: $iconActiveColor !important;
  }
}
