@import "../../../../theme/variables.scss";

.page-body-settings-grafana {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .settings-grafana {
    background-color: var(--systemBackground);
    padding: 34px;
    height: 100%;
    width: 100%;

    .container {
      display: flex;
      height: 100%;
      flex-direction: column;

      .header {
        height: 60px;
        padding: 18px 30px;
        border-bottom: 1px solid var(--systemBackground);
        border-radius: $inputBorderRadius $inputBorderRadius 0 0;
        background: $systemForeground;

        h3 {
          margin: 0;
          color: var(--textPrimary);
        }
      }

      .settings-content {
        height: 100%;
        display: flex;

        .settings-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          .container-area {
            background: $systemForeground;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            padding: 30px 30px;

            .group-container-grafana {
              width: 100%;
              max-width: 720px;

              h3.page-header {
                font-size: 16px;
                font-weight: 600;
                color: #344054;
                margin: 0;
              }

              .page-header {
                border-bottom: $defaultBorder;
                padding-bottom: 10px;
              }

              .custom-input-container {
                width: 100%;
                flex: auto;
              }

              .subgroup-container-grafana {
                margin-top: 20px;
                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: #344054;
                  margin: 0;
                  padding-bottom: 6px;
                }

                .settings {
                  display: flex;

                  .setting {
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                  }
                }

                .form-row-grafana-url {
                  width: 100%;
                }
              }
            }
          }
        }
        .footer {
          height: 60px;
          flex-shrink: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 30px;
          border-top: 1px solid var(--systemBackground);
          border-radius: 0 0 $inputBorderRadius 0;
          background: $systemForeground;
        }
      }
    }
  }
}
