@import "../../../../theme/variables.scss";

.area-side-menu {
  width: 65px;
  overflow: hidden;
  position: absolute;
  right: 0;
  background: $systemForeground;
  z-index: 2;
  transition: width 0.3s ease;

  .menu-container-placeholder {
    visibility: visible;
    padding: 50px 10px;
    width: 100%;

    .category {
      width: fit-content;
      padding: 10px;
      border-radius: $inputBorderRadius;

      &:hover {
        cursor: pointer;
        background: var(--primary-50, #f5f5fd);
      }
    }
  }

  .menu-container {
    visibility: hidden;
    padding: 20px;
    width: 330px;

    .left-column {
      border-radius: 0 0 0 6px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .skeleton-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      p {
        margin: 0;
        color: $textPrimary;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 16px;
          font-weight: 700;
        }

        .icon:hover {
          cursor: pointer;
          background: #f5f8ff;
          border-radius: $inputBorderRadius;
        }
      }

      .description {
        font-size: $defaultFontSize;
        font-weight: 300;
        margin-bottom: 30px;
      }

      .search-input {
        margin-bottom: 30px;
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        background: var(--inputBackgroundColor, #fff);
        padding: 0px 12px;
        border-radius: 8px;

        .icon-search {
          display: flex;
          justify-content: space-between;
        }

        .p-inputtext {
          background-color: var(--inputBackgroundColor);
          width: 100%;
        }
      }

      .p-button-text {
        font-size: $defaultFontSize;
      }

      .p-button {
        display: flex;
        font-size: 14px;
        justify-content: center;
        margin-bottom: 30px;
        gap: 8px;
      }

      .groups-list {
        height: 100%;
        max-height: 650px;
        overflow: auto;
        scroll-behavior: smooth;

        .group-divider {
          padding: 10px;
          font-weight: bold;
        }

        ul {
          max-height: 100%;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .rack-group-wrapper {
          display: flex;
          gap: 10px;

          &:hover {
            .icon-btn {
              display: block;
            }
          }

          .icon-btn {
            width: fit-content;
            margin: 0;
            display: none;
          }
        }

        .empty-message-wrapper {
          padding: 10px;
        }

        li {
          display: flex;
          justify-content: space-between;
          list-style-type: none;
          width: 100%;
          border-radius: $buttonBorderRadius;
          background: var(--databaseFieldsBackgroundColor);
          padding: 10px 16px;
          margin-bottom: 15px;
          color: $textPrimary;
          font-size: $buttonFontSize;
          //transition: 0.3s;

          &.added {
            border: 3px solid #8689ff;
          }

          .icon {
            display: flex;
            width: 20px;
            justify-content: center;
            align-items: center;
          }

          &:hover {
            cursor: pointer;
            background: #8689ff;

            span {
              color: white;
            }

            svg {
              path {
                stroke: white;
              }
            }
          }
        }
      }
    }
  }

  .menu-container,
  .menu-container-placeholder {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &.open {
    width: 330px;

    .menu-container-placeholder {
      display: none;
    }

    .menu-container {
      visibility: visible;

      .menu-header {
        opacity: 1;
      }
    }
  }
}
