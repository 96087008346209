@import "../../../theme/variables.scss";

.granted-access-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  transition: opacity 300ms;
  opacity: 1;

  .granted-access-body {
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    transition: 0.3;
    .description-access-granted {
      height: 25px;
      display: flex;
      color: #4fd9ae;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 25px;
    }
  }

  .error-container{
    display: flex;
    gap: 10px;
    flex-direction: column;
    max-width: 200px;
    text-align: center;
    align-items: center;
    font-weight: 500;
  }
}

@media (max-width: $mobileBreakPoint) {
  .granted-access-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
