@import "../../../../../theme/variables.scss";

.config-side-menu {
  border-top: 1px solid var(--systemBackground);
  position: fixed;
  right: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  background: var(--systemForeground);
  height: 100%;
  z-index: 100;
  transition: width 0.3s ease;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .menu-container {
    visibility: hidden;
    padding: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-header {
      opacity: 0;
      display: flex;
      flex-direction: column;
      transition: opacity 0.3s ease;

      .title {
        display: flex;
        justify-content: flex-end;

        .config-label {
          margin: 0;
          color: var(--textPrimary);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          width: 100%;
        }

        .icon:hover {
          cursor: pointer;
          background-color: var(--iconActiveColor);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          padding: 1px;
        }
      }
    }

    .config-form {
      gap: 20px;
    }

    .actions {
      justify-content: flex-end;
    }
  }

  &.open {
    width: 400px;

    .menu-container-placeholder {
      display: none;
    }

    .menu-container {
      visibility: visible;
      background-color: var(--systemForeground);

      .menu-header {
        opacity: 1;
      }
    }
  }
}
