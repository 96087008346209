@import "../../../theme/variables.scss";

.ai-analytics-dialog {
  width: clamp(50vw, 1020px, 1020px);
  height: clamp(90vh, 870px, 870px);
  display: flex;
  flex-direction: column;

  .p-dialog-header {
    border-bottom: 1px solid var(--systemBackground);

    .ai-analytics-dialog-header {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    height: 90vh;

    .ai-analytics-dialog-content {
      flex: 1;

      .ai-analytics-dialog-content-container {
        max-width: 582px;
        margin: auto;
        display: flex;
        flex-direction: column;
        height: 100%;

        .ai-analytics-responses {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow: auto;
          padding: 20px 0px;
          flex: 1;

          .ai-analytics-dialog-message {
            display: flex;
            padding: 10px;
            gap: 8px;
            width: fit-content;
            border-radius: 10px;
            font-size: 14px;
            line-height: 28px;
            flex-direction: column;
            align-items: flex-start;

            &.user-message {
              background-color: $primary;
              color: #fff;
              align-self: flex-end;
            }

            &.system-message {
              background-color: var(--systemBackground);
              color: $textSecondary;
              align-self: flex-start;
            }
          }
        }

        .ai-analytics-user-area {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .ai-analytics-user-area-message {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            color: $textSecondary;
            .ai-analytics-user-area-message-user-name {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
            }
          }
          .analytics-actions-step-control {
            display: flex;
            width: 100%;
            gap: 12px;
            .btn-ai-analytics-actions-step-control {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
              background-color: var(--inputBackgroundColor);
              border-color: var(--inputBorderColor);
              color: var(--textPrimary);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
            }
          }

          .ai-analytics-custom-input-container {
            width: 100%;
            .ai-analytics-custom-input-wrapper {
              display: flex;
              padding: 8px;
              width: 100%;
              background-color: $systemForeground;
              border-radius: 10px;
              border: 1px solid $blueGray200;

              &.disabled {
                background-color: var(--inputBackgroundColor);
                cursor: not-allowed;
              }

              .ai-analytics-custom-input {
                flex: 1;
                display: flex;
                background-color: transparent;
              }
              .btn-submit {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                border-radius: 6px;
                background-color: var(--systemForeground);
                border: none;
                cursor: pointer;
              }
            }
          }
        }
        .ai-analytics-sugestions-area {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .ai-analytics-user-selection-options-title {
            padding: 10px;
            border-radius: 6px;
            background-color: var(--systemBackground);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: $textSecondary;
            width: fit-content;
          }

          .ai-analytics-sugestions-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: $textSecondary;
          }
          .ai-analytics-sugestions-container {
            display: flex;
            flex-wrap: nowrap;
            gap: 12px;

            .ai-analytics-sugestion {
              display: flex;
              flex-direction: column;
              flex: 1;
              padding: 10px;
              border-radius: 10px;
              border: 1px solid var(--systemBackground);
              background: transparent;
              cursor: pointer;
              gap: 5px;

              &:hover {
                background: $blueGray50;
              }

              .ai-analytics-sugestion-title {
                color: $gray500;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
              }

              .ai-analytics-sugestion-content {
                color: $gray400;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
              }
            }
            .ai-analytics-suggestion-container-blocked {
              display: flex;
              flex-direction: column;
              flex: 1;
              padding: 10px;
              border-radius: 10px;
              border: 1px solid var(--systemBackground);
              background: transparent;
              gap: 5px;
              cursor: not-allowed;

              &:hover {
                background: none !important;
              }
            }
          }
          .ai-analytics-dialog-suggestion-option {
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 10px;
            border: 1px solid var(--inputBorderColor);
            padding: 10px;
            width: fit-content;
            background-color: var(--systemForeground);

            &:hover {
              background-color: var(--inputBackgroundColorHover);
            }
          }
        }
      }
    }
  }
}
