@import "./variables.scss";
@import "./components/dcp-table.scss";
@import "./overrides.scss";
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

@font-face {
  font-family: "dcp-icons";
  src:
    local("dcp-icons"),
    url("fonts/dcp-icons.woff") format("woff");
}

@font-face {
  font-family: "Quicksand";
  src:
    local("Quicksand"),
    url("fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
  font-weight: 300 700;
  font-style: normal;
}

* {
  font-family: "Quicksand";
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 13.44px;

  a {
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: none !important;
  }

  body {
    font-family: $fontFamily;
    margin: 0 auto;
    overflow: hidden;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: var(--systemForeground);
}
::-webkit-scrollbar-thumb {
  background: var(--systemBackground);
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--textTertiary);
}
