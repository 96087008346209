@import "../../../../theme/variables.scss";

.create-template-modal {
  .create-template-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;

    .create-template-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 1rem 1rem 0;
    }

    .create-template-form-wrapper {
      padding: 30px 40px;
    }
  }
  .p-dialog-footer {
    display: flex;
    padding: 0px 30px;
    align-items: center;
    height: 80px;
    border-top: solid 1px var(--systemBackground);

    .modal-footer {
      display: flex !important;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .dcp-btn-wrapper-template-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
