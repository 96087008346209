@import "../../../../theme/variables.scss";

.dialog-location-details {
  display: flex;
  max-height: 100%;
  padding: 0px;
  .p-dialog-header {
    display: none;
  }
  .barcode-read {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 400;

    .barcode-read-content {
      background-color: var(--systemForeground);
      display: flex;
      height: fit-content;
      flex-grow: 1;
      border-radius: 6px;

      .barcode-details {
        display: flex;
        flex-grow: 1;
        width: 100%;
        max-width: 370px;
        height: 100%;
        flex-direction: column;
        padding: 20px 30px;
        gap: 15px;
        background-color: #ffffff;
        border-radius: 6px 0px 0px 6px;
        overflow: auto;

        .return-option-wrapper {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .icon-return {
            display: flex;
            cursor: pointer;
          }
          .return-text-title {
            font-weight: 700;
            font-size: 16px;
            color: $textPrimary;
          }
        }

        .barcode-form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .issue-details-wrapper {
          gap: 6px;
          display: flex;
          flex-direction: column;
          .issue-details-text {
            display: flex;
            background-color: #f5f8ff;
            border-radius: 8px;
            align-items: center;
            padding: 10px 14px 10px 14px;
          }
        }
        .input-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          &.lpn-status {
            flex-direction: row;
            gap: 10px;
            align-items: center;
          }
        }

        .input {
          flex-grow: 1;

          .p-inputtext,
          .p-dropdown,
          .p-inputnumber {
            color: #000;
            width: 100%;
            font-weight: 600;
            &.lpn-status {
              border: 1px solid #f5f8ff;
              background-color: var(--dropdown-selected-background-color);
              .p-dropdown-label {
                color: var(--dropdown-selected-text-color);
              }
            }
          }
        }

        .sku-wrapper {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          flex-direction: row;
          margin-top: 10px;
          .icon-add {
            display: flex;
            cursor: pointer;
          }
        }

        .dcp-form {
          padding: 0;
          .form-row {
            flex-wrap: wrap;
          }
        }

        // itens-table component
        .itens-table {
          width: 100%;
          height: 100%;
        }

        .sku-list {
          display: flex;
          justify-content: space-between;
          padding: 14px 12px;
          border-radius: 8px;
          background: #f5f8ff;
          min-width: 310px;

          .sku-name-label {
            display: flex;
            width: 100%;
            padding: 5px 5px 5px 5px;
            font-weight: 600 !important;
            font-size: 14px;
            color: var(--itemColor);
          }

          .sku-dropdown-label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 24px;
            padding: 2px 5px 2px 0px;
            font-weight: 600 !important;
            font-size: 14px;
            border-radius: 4px;
            border: 1px solid var(--gray-300, #d0d5dd);
            background: var(--white, #fff);
            .p-placeholder {
              color: var(--itemColor);
            }
          }

          .dcp-sku-table {
            width: 100%;

            table {
              width: 100%;
              thead {
                display: none;
                .p-editable-column {
                  color: inherit;
                }
                tr {
                  th {
                    text-align: left;
                    padding: none !important;
                    border: none !important;
                    color: none;
                    background: none;
                    transition: box-shadow 0.2s;
                  }
                }
              }
              tbody {
                display: flex;
                gap: 8px;
                flex-direction: column;
                width: 100%;
                tr {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  height: 34px;
                  color: #344054;
                  background-color: #f5f8ff;
                  transition: box-shadow 0.2s;
                  td {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border: none;
                    border-width: 0 0 1px 0;

                    .list-content {
                      align-items: center;
                      display: flex;
                      justify-content: space-between;
                      width: 100%;

                      .item-name {
                        display: flex;
                        width: 100%;
                      }
                      .action-list {
                        display: flex;
                        justify-content: right;
                        gap: 8px;
                        .p-inputtext {
                          width: 100%;
                          padding: 5px 0px 4px 4px;
                        }
                        .list-button {
                          height: 100%;
                          padding: 1px;
                          .icon-list:hover {
                            filter: brightness(0.5);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .scan-date {
          display: flex;
          gap: 10px;
          flex-direction: column;
          margin: 20px 0px 30px 0px;
          .scan-datetime {
            font-weight: 600;
          }
        }

        .other-locations-wrapper {
          .location-street {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 8px 0px;
            border-left: 2px;
            .locations-tag {
              width: 3px;
              height: 15px;
              border-radius: 6px;
              background-color: #fd7584;
            }
            .locations-name {
              font-weight: 600;
              font-size: 14px;
              color: $inputFontColor;
            }
          }
        }
      }

      .barcode-image {
        display: flex;
        flex-grow: 2;
        flex-direction: column;
        align-items: center;
        background: #f8f9fc;

        .barcode-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          margin-top: 30px;
        }

        .location-image {
          width: 100% !important;
          padding: 23px 35px 23px 35px !important;
        }

        .image-gallery {
          display: flex;
        }

        .main-image-container {
          flex: 1;
          text-align: center;
        }

        .paginator {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .paginator-icon {
            width: 3rem;
            padding: 1px;
            margin: 0px 10px 0px 10px;
          }
        }
      }

      .barcode-thumbnail-session {
        align-items: center;
        display: flex;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        max-width: 130px;
        flex-direction: column;
        padding: 30px 18px;

        .barcode-image-thumbnail-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .thumbnail-gallery {
          display: flex;
          flex-direction: column;
        }

        .thumbnail {
          width: 94px;
          height: 69px;
          margin-bottom: 5px;
          cursor: pointer;
          opacity: 0.5;
        }

        .thumbnail.selected {
          opacity: 1.5;
          border: 2px solid #4146ff;
        }
      }
    }

    hr {
      width: 100%;
      border: 0;
      height: 1px;
      margin: 30px 0px;
      background: #d5d9eb;
    }
  }
}

.dcp-popup-delete-confirmation {
  display: flex;
  width: 334px;
  height: 155px;
  .p-overlaypanel-content {
    padding: 18px;
    .popup-wrapper {
      display: flex;
      flex-direction: column;
      .popup-header {
        display: flex;
        height: 100%;
        display: flex;
        justify-content: space-between;
        gap: 18px;
        .popup-icon-wrapper {
          padding: 0px 0px 5px;
          .popup-icon-delete {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ffe8ea;
            border: 8px solid #ffeff0;
          }
        }
        .popup-body-wrapper {
          .popup-title-wrapper {
            display: flex;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            .popup-title-message {
              color: var(--textPrimary);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }

          .popup-description-wrapper {
            .popup-body-message {
              color: var(--textTertiary);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
      .popup-footer-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

// Status dropdown colors
.color-EE46BC {
  color: #ee46bc;
}

.color-03CEB7 {
  color: #03ceb7;
}

.color-FFCF0F {
  color: #ffcf0f;
}
