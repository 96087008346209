@import "../../../theme/variables.scss";

.p-menu {
  padding: 0.25rem 0;
  background: var(--systemForeground) !important;
  color: $textSecondary;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1.25rem;
  color: $textPrimary;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;

  .icon {
    color: $textSecondary !important;
  }
}
.p-checkbox .p-checkbox-box {
  background: var(--iconNavBarActiveColor);
  border: 1px solid $systemContentBorderColor;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: $textPrimary !important;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: $textPrimary !important;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: $textPrimary !important;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: $tableHeaderBackgroundHover !important;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: $textPrimary !important;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: $textPrimary !important;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: $textPrimary !important;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ebf1ff;
}
.p-menu.p-menu-overlay {
  background: $systemBackground;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: var(--textPrimary);
  background: var(--systemForeground);
  font-weight: 700;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--systemBackground);
  margin: 0.25rem 0;
}
