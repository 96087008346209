@import "../../../theme/variables.scss";

.confirmation-dialog {
  display: flex;
  width: 485px;
  padding: 24px;
  flex-direction: column;
  background: $systemForeground;
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  .p-dialog-header {
    display: flex;
    padding: 0;
    align-items: flex-start;
    align-self: stretch;

    .p-dialog-header-icons {
      display: none;
    }

    .modal-header {
      padding: 0;

      .modal-icon-help {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: #eeeefd;
        border: 8px solid #f5f5fd;
      }

      .modal-icon-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: #ffe8ea;
        border: 8px solid #ffeff0;
      }
    }
    .modal-title-wrapper {
      width: 100%;
      font-weight: 600;
      font-size: 23px;
      line-height: 30px;
      height: fit-content;
      padding-top: 12px;

      .modal-title {
        padding: 0;
        margin: 0;
      }
    }
  }

  .p-dialog-content {
    text-align: justify;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0;

    .p-confirm-dialog-message {
      padding-top: 8px;
      padding-bottom: 20px;
      margin: 0;
    }
  }

  .card.flex.justify-content-center {
    margin: 0px -7px 0.2rem 1.5rem !important;
  }

  .p-dialog-footer {
    padding: 0;
  }
}
