@import "../../../../theme/variables.scss";

.floor-area {
  height: 100%;
  padding: 20px;

  .table-wrapper {
    background-color: var(--systemForeground);
    border-radius: $panelBorderRadius;
    box-shadow: $defaultBoxShadow;
    display: flex;
    flex-direction: column;

    .datatable-container {
      flex-grow: 1;
    }
  }

  .color-column-template {
    display: flex;
    align-items: center;
    gap: 10px;

    .color-circle {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }

  .column-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    .column-actions {
      display: flex;
      height: 30px;
      align-items: center;

      .icon-wrapper {
        display: flex;
        justify-content: center;
        padding: 10px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          padding: 10px;
          border-radius: 8px;
          background: #f5f5fd;
        }
      }
    }
  }

  .p-paginator-bottom {
    background: none;
  }
}
