.barcode-splitter {
  width: 100vw;
  height: 100vh;
  background: var(--systemBackground);
  padding: 34px;

  .container {
    padding: 20px;
    width: 100%;
    height: 100%;
    background: var(--systemForeground);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;

    .form-container {
      width: 100%;
      max-width: 400px;
      flex-basis: auto;
    }

    .tabs-container {
      overflow-y: scroll;
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tab-wrapper {
        display: flex;
        gap: 10px;

        .keybind {
          margin: 0;
          text-align: center;
          margin-bottom: 4px;
          color: #c5c8e3;
        }

        .tab {
          cursor: pointer;
          padding: 8px 7px;
          border-radius: 4px;

          &:hover {
            background: #eff1fe;
          }

          &.selected {
            background: #d7daf3;
          }

          span {
            margin: 0;
            -webkit-user-select: none;
            /* Safari */
            -ms-user-select: none;
            /* IE 10 and IE 11 */
            user-select: none;
            /* Standard syntax */
          }
        }
      }

      .tab-content {
        padding-top: 20px;
      }
    }

    .list-container {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
    }

    .barcode-wrapper {
      margin-top: 15px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      flex-grow: 1;

      &.rotate {
        margin: 70px 0;
      }

      span {
        font-weight: 800;
        font-size: 16px;
      }
    }

    .center-row-details {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: nowrap;

      .center-items {
        font-weight: 600;
        font-size: 20px;
        color: red;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
      }
    }

    .input-hidden {
      position: absolute;
      height: 1px;
      width: 1px;
      border-color: transparent;
      background-color: transparent;
      margin-top: -10px;
      z-index: -1000;
    }
  }
}