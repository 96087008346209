@import "../../../theme/variables.scss";

.add-item-popup-container {
  display: flex;

  .btn-new-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--systemForeground);
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    margin-right: 10px;
  }
  .new-dashboard-pop-up {
    position: absolute;
    min-width: 200px;
    background-color: var(--surface-a);
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    z-index: 1000;
    border-radius: 6px;
    background: var(--systemForeground);
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(30, 40, 60, 0.1);

    .new-dashboard-pop-up-title {
      display: flex;
      padding: 4px 20px;
      align-items: center;
      gap: 30px;
      white-space: nowrap;
      color: var(--textPrimary);
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
    }

    .new-dashboard-pop-up-action {
      display: flex;
      padding: 7px 20px;
      align-items: center;
      gap: 10px;
      color: var(--textTertiary);
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
    }
  }
}
