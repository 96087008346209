@import "../../../../theme/variables.scss";

.template-list-container {
  height: 100%;
  padding: 20px;

  .table-wrapper {
    background-color: var(--systemForeground);
    border-radius: $panelBorderRadius;
    box-shadow: $defaultBoxShadow;
    display: flex;
    flex-direction: column;

    .datatable-container {
      .status-cell {
        display: flex;
        align-items: center;
        .status-identification {
          font-size: 14px;
          display: inline-flex;
          font-weight: 500;
          height: 22px;
          padding: 4px 0px;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          border-radius: 16px;
          gap: 3px;
          &.active {
            width: 56px;
            color: $tableStatusLabelActive;
            background: $tableStatusLabelBackgroundActive;
          }

          &.inactive {
            width: 68px;
            color: $tableStatusLabelInactive;
            background-color: $tableStatusLabelBackgroundInactive;
          }
        }

        .user-name {
          flex: 1 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: wrap;
        }
      }

      .column-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        .column-actions {
          display: flex;
          height: 30px;
          align-items: center;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            padding: 10px;
            transition: 0.2s;
            border-radius: 8px;
            cursor: pointer;

            .icon-duplicate,
            .icon-delete,
            .icon-edit {
              color: var(--tableIconColor) !important;
            }
            &:hover {
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
            }
          }
        }
      }
    }
  }

  .p-paginator-bottom {
    background: none;
  }
}
