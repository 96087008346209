@import "./variables.scss";

// Primereact overrides
.p-button {
  .dcp-icon {
    color: $primaryButtonFontColor;
  }
}

.p-multiselect {
  border: 1px solid var(--systemBackground);
  background: var(--systemBackground);
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;

  &:hover {
    border: 1px solid var(--systemBackground);
  }

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--systemBackground);
    border-color: var(--systemBackground);
  }

  .p-multiselect-label {
    border: none;
  }
}

.p-avatar {
  background: #f5f5fd;

  .p-avatar-text {
    color: var(--primary-600, #4146ff);
  }
}

.p-inputtext::placeholder {
  font-size: 16px;
}

.p-panel-content {
  border: $defaultBorder;
}

.p-inputgroup-addon {
  border: none;
  box-shadow: none;
}

.p-tooltip {
  box-shadow: none;

  .p-tooltip-text {
    background: $systemForeground;
    color: $textPrimary;
  }

  .p-tooltip-arrow {
    border-bottom-color: $systemForeground !important;
  }
}
