@import "../../../theme/variables.scss";

.connections-subgroup {
  h3 {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--textPrimary);
  }

  .pattern-description {
    margin: 0;
  }

  .pattern-field {
    width: 100%;

    .p-inputtextarea {
      width: 100%;
      resize: none;

      height: 38px;
    }
  }

  .search-fields {
    width: 100%;
  }
}
