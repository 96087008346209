@import "../../../../theme/variables.scss";

.dynamic-reports {
  height: 100%;
  margin: 30px;
  background: var(--systemForeground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: solid 1px var(--painelBorderColor);
  border-radius: $panelBorderRadius;

  .button-actions {
    display: flex;
    gap: 10px;

    .download-btn {
      padding: 10px;
    }
  }

  .reports-datatable {
    .p-datatable-tbody > tr {
      background-color: var(--systemForeground);
    }
  }

  .table-actions-column {
    display: flex;
    gap: 10px;

    .action-icon {
      padding: 10px;
      cursor: pointer;
      border-radius: 10px;

      &:hover {
        background: var(--systemBackground);
      }
    }
  }

  .p-paginator {
    border-radius: $panelBorderRadius;
    background: var(--systemForeground);
  }

  .access-pill {
    border-radius: 10px;
    width: fit-content;
    padding: 2px 8px;

    span {
      font-size: 14px;
      font-weight: 600;
    }

    &.public {
      background: var(--systemBackground);
      span {
        color: #03ceb7;
      }
    }

    &.private {
      background: var(--systemBackground);
      span {
        color: #6172f3;
      }
    }
  }

  .users {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    span {
      background: #f2f4f7;
      padding: 4px 8px;
      border-radius: 4px;
      color: $textPrimary;
      font-size: 12px;
      font-weight: 400;
    }

    .remaining-users {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: var(--Gray-100, #f2f4f7);
    }
  }
}

.download-list-columns-reports {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #f2f4f7);

  .p-overlaypanel-content {
    padding: 0px;
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid #f2f4f7;
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--gray-700, #344054);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-download-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;

        .popup-excel-download-option,
        .popup-pdf-download-option,
        .popup-csv-download-option {
          cursor: pointer;
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;
          transition: 0.2s;

          .popup-download-label {
            cursor: pointer;
            color: #667085;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          &:hover {
            background-color: var(--tableActionButtonHoverBackgroundColor);
          }
        }
      }
    }
  }
}
