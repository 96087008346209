@import "../../../../../../../../theme/variables.scss";

.performance-percentage-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .performance-percentage {
    position: relative;
    align-items: flex-end;
    justify-content: center;
    width: 240px;
    height: 137px;

    svg {
      position: absolute;
    }

    .label-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      p {
        margin: 0;
      }

      .label {
        color: $textSecondary;
        font-size: 14px;
        font-weight: 500;
        padding: 0;
      }

      .percentage {
        color: $textSecondary;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}
