@import "../../theme/variables.scss";

.auth-layout {
  background-color: $systemBackground;
  overflow: hidden;

  .auth-side-menu-splitter-panel-container {
    display: flex;
    flex: 1;
  }

  .auth-content-splitter-panel-container {
    display: flex;
    flex: 1;
    overflow: auto;
    height: calc(100vh - 65px);
  }
}
