@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.print-action-document-main-container {
  display: flex;
  height: 100%;
  color: var(--gray-700);
  background: var(--blue-gray-50);
  flex-direction: column;
  padding: 30px;

  .backdrop {
    background: var(--systemForeground);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  &.fullscreen {
    border-radius: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 18px;
    background: rgba(16, 24, 40, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .backdrop {
      min-height: calc(100vh - 36px);
      height: 100%;
      width: 100%;
    }
  }

  .print-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    .container {
      width: 100%;
      height: 100%;
      background: $systemForeground;
      border-radius: 6px;
      background-color: $systemForeground;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        height: 60px;
        border-bottom: solid 1px var(--systemBackground);
        padding: 8px 15px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .title-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 5px;

          .header-message {
            .title {
              color: var(--textPrimary);
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
            }
          }

          .total-items-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 22px;

            .total-items-count {
              padding: 2px;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              color: var(--primary-600, #4146ff);
            }
          }
        }

        .options-wrapper {
          display: flex;
          gap: 20px;
          border-right: 1px solid var(--gray-250, #dee0e3);

          .search-input {
            display: flex;
            align-items: center;
            width: 220px;
            height: 40px;
            background: var(--inputBackgroundColor, #fff);
            border-radius: 8px;
            padding: 10px 14px;
          }

          .icon-options {
            display: flex;
            gap: 5px;
            margin-right: 20px;

            .btn-column {
              border: none;
              background: none;
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              transition: 0.2s;

              &:hover {
                border: none;
                padding: 10px;
                border-radius: 8px;
                background: #f5f5fd;
                box-shadow: none;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }

        .icon {
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 4px;

          &:hover {
            background: $systemBackground;
            cursor: pointer;
          }
        }
      }

      .style-address {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        width: fit-content;
        height: 32px;
        background-color: #f5f5fd;
        color: #4146ff;
        padding: 9px;
        border-radius: 8px;
      }

      .containerTables {
        display: flex;
        width: 100%;
        height: 100%;

        .tableLeft {
          display: flex;
          justify-content: center;
          flex: 1 1;
          flex-direction: column;
          gap: 30px;
          padding: 20px 30px;

          .entry-settings-side-fields {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            .entry-settings-form {
              display: flex;
              gap: 5px;
              width: 450px;

              .inputText {
                width: 17vw;
                min-width: 200px;
              }
            }

            .loading-fields-wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }

          .side-menu-title {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 30px;

            .tableLeft-title {
              display: flex;
              color: var(--textPrimary);
              align-items: center;
              height: 24px;
              font-size: 1.1em;
              font-weight: 600;
            }
          }
        }
      }

      .entryFooter {
        display: flex;
        justify-content: end;
        align-items: end;
        flex-grow: 1;
        padding: 10px;
        border-top: solid 1px var(--systemBackground);

        .entry-manage-data-buttons {
          display: flex;
          gap: 20px;

          .clear-data {
            color: #f9485b;
          }

          .clear-data:hover {
            background-color: #ffe8ea;
          }
        }
      }
    }
  }

  .wms-login-dialog {
    width: 400px;

    .p-dialog-content {
      padding-bottom: 0px;
    }

    .buttom-container {
      justify-content: flex-end;
    }
  }

  .wms-password {
    .p-password-input {
      width: 100%;
    }
  }
}

@media (max-width: $mobileBreakPoint) {
  .entry-settings-form {
    display: flex;
    flex-direction: column;
    width: 100% !important;

    .inputText {
      width: 100% !important;
    }
  }
}
