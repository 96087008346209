.dcp-color-picker {
  width: 40px;
  height: 38px;

  .color-button {
    border: none;
    border-radius: 20%;
    cursor: pointer;
  }

  .color-picker-container {
    position: absolute;
    z-index: 9999;
  }
}
