@import "../../../../theme/variables.scss";

.dashboard-graphs {
  display: flex;

  &.full {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .react-grid-item.cssTransforms {
    transition-property: none;
  }

  #grid-layout-container {
    padding: 0 10px;
  }

  .main-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background: $systemBackground;

      h3,
      .dashboard-name-input {
        margin: 0;
        color: $textSecondary;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
      }

      .actions {
        display: flex;
        gap: 10px;

        .p-button {
          width: fit-content;
          padding: 0 12px;
          .btn-icon-fullscreen {
            color: $iconDefaultColor;
          }
          .cancel-edit-button {
            background-color: $primaryButtonBackgroundColor !important;
          }
        }
        .dashboard-add-item-icon {
          color: $tableHeaderTitle !important;
        }
      }
      .p-button {
        width: fit-content;
      }

      .menu-btn {
        padding: 0;
      }
    }

    .content-container {
      background: $systemBackground;
      flex-grow: 1;
      overflow: auto;

      .react-grid-item {
        .react-resizable-handle {
          visibility: hidden;
        }

        &:hover {
          .react-resizable-handle {
            visibility: visible;
          }
        }
      }

      .placeholder-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .placeholder {
          gap: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 400px;
          padding: 24px;
          background-color: $systemForeground;
          border-radius: 12px;
          border: 1px dashed var(--gray-400, #98a2b3);

          p {
            text-align: center;
            margin: 0;
          }

          .title {
            color: var(--textPrimary);
            font-size: 18px;
            font-weight: 600;
          }

          .description {
            color: var(--textTertiary);
            font-size: 14px;
            font-weight: 400;
          }

          .add-item {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
