@import "../../../../theme/variables.scss";

.create-group {
  display: flex;
  width: 100%;
  background: $systemForeground;
  border-radius: 0px 0px 8px 8px;
  padding-bottom: 5px;
  margin-bottom: 12px;

  .left-column {
    background: var(--systemForeground);
    border-right: 1px solid var(--systemBackground);
    padding: 20px 30px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    width: 300px;
    flex-shrink: 0;
    border-radius: 0 0 0 8px;

    .header {
      p {
        margin: 0;
        color: var(--textTertiary);
      }

      .title {
        color: var(--textPrimary);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    .search-input {
      display: flex;
      width: 100%;
      align-items: center;
      background: var(--systemBackground);
      padding: 0px 12px;
      border-radius: 8px;

      .icon-search {
        display: flex;
        justify-content: space-between;
      }

      .p-inputtext {
        width: 100%;
        background: $systemBackground;
      }
    }

    .rack-list {
      overflow-y: auto;
      flex-grow: 1;

      ul {
        margin: 0;
        padding: 0;
        width: 100%;
      }

      li {
        list-style-type: none;
        &:hover {
          cursor: pointer;
        }
      }

      .rack {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        background: var(--rackInternSideMenuButtonBackgroundColor);
        border-radius: 6px;

        &:hover {
          background: #8689ff;
          span {
            color: $systemForeground;
          }
        }

        span {
          color: $textPrimary;
          font-size: 16px;
          font-weight: 500;
        }

        .rack-name,
        .icon {
          padding: 10px 12px 10px;
        }

        .rack-name {
          color: var(--textPrimary);
          width: 100%;
          transition: all 0.1s ease;
          border-radius: 6px 0 0 6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .icon {
          width: 46px;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }

  .right-column {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;

    .form-container {
      display: flex;
      padding: 20px 30px;

      .config {
        display: flex;
        flex-direction: column;
        flex: 6;

        .dcp-form {
          padding: 0;
          .form-row {
            flex-wrap: wrap;
          }
        }

        .title {
          margin-top: 10px;
          margin-bottom: 10px;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 700;
        }

        &:first-child {
          padding-right: 20px;
          flex: 4;
        }

        .p-inputgroup {
          width: 260px;
          .p-dropdown {
            max-width: 80px;
          }

          .p-dropdown-trigger {
            width: 30px;
          }

          .p-inputtext {
            flex-basis: 0;
            min-width: 0;
            font-size: 16px;
          }
          .p-inputgroup-addon.horizontal,
          .p-inputgroup-addon.vertical {
            border: none;
            background-color: var(--systemBackground);
          }
        }

        .manual-config-switch {
          position: relative;
          margin-top: 8px;
          margin-bottom: 5px;
        }
      }
    }

    .rack-container {
      background: #fcfcfd;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .header {
        background-color: var(--tableHeaderBackground);
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;

        span {
          font-size: $defaultFontSize;
          font-weight: 500;
          color: var(--gray-500);
        }
      }

      .rack-render-area {
        background-color: var(--tableHeaderBackground);
        overflow: auto;
        flex-grow: 1;

        .generated-rack {
          background-color: var(--tableHeaderBackground);
          padding: 5px 15px;
          height: 100%;
          flex-grow: 1;
          display: flex;
          align-items: center;

          .wrapper {
            display: flex;
            flex-direction: column;
            margin-left: -12px;
            margin-right: 12px;
            margin-bottom: 8px;
            background: var(--tableHeaderBackground);

            &:first-child {
              background-color: var(--tableHeaderBackground);
              margin: 0;
            }

            &:last-child {
              margin-bottom: 0;
              padding-right: 15px;
            }

            .header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 80%;
              background: var(--systemBackground);
              border-radius: 8px;
              margin: 0 auto;
              margin-bottom: 10px;
              padding: 8px;

              .name {
                display: flex;
                .color {
                  height: 18px;
                  width: 8px;
                  border-radius: 2px;
                  margin-right: 23px;
                }
              }

              .icon {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .buttons-bar {
      height: 60px;
      padding: 0 30px;
      display: flex;
      justify-content: flex-end;
      border-radius: 0 0 $inputBorderRadius 0;

      .button-container {
        padding: 10px 0;
        display: flex;
        gap: 20px;
        width: 100%;

        .group-delete-btn {
          margin-right: auto;
        }
      }
    }
  }
}
