@import "../../../../../theme/variables.scss";

.create-inventory-dialog {
  width: calc(100vw - 15px);
  height: calc(100vh - 15px);
  max-height: 100%;
  max-width: 100%;

  .p-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .p-error {
    height: 14px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 920px;

    .content {
      display: flex;
      gap: 50px;

      .inventory-options {
        width: 50%;

        .title {
          margin: 0;
          color: $textPrimary;
          font-size: 20px;
          font-weight: 600;
        }

        .description {
          color: $textPrimary;
          font-size: 12px;
          font-weight: 400;
        }

        .inputs {
          margin-top: 30px;
          padding-bottom: 0;

          .areas-selection {
            background-color: var(--inputBackgroundColor) !important;
            border: none;

            .p-multiselect-trigger {
              background-color: var(--inputBackgroundColor) !important;
              border: none;
            }
          }
        }
      }

      .equipment-type {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 20px;

        .label {
          color: var(--textPrimary);
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          padding: 0;

          &.type {
            margin-bottom: 6px;
          }
        }

        .read-type {
          display: flex;
          gap: 20px;

          .radio {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        .type-card {
          height: 110px;
          display: flex;
          border-radius: 10px;

          /* Shadow/sm */
          box-shadow:
            0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);

          &.active {
            border: 1px solid var(--primary-600, #4146ff);
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            background: var(--gray-100, #f2f4f7);
            border-radius: 10px 0 0 10px;

            svg {
              width: 80px;
              height: 80px;
            }
          }

          .card-content {
            padding: 10px 20px;
            flex: 1;

            .title {
              margin: 0;
              padding: 0;
              color: var(--textTertiary);
              font-size: 16px;
              font-weight: 700;
            }

            .description {
              margin: 3px 0 0 0;
              color: $textPrimary;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .check-container {
            display: flex;
            align-items: center;
            width: 40px;
          }
        }

        .selection {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;

          .users-selection,
          .sku-selection,
          .location-selection,
          .drones-selection {
            background-color: var(--inputBackgroundColor) !important;
            border: none;
            max-width: 420px;

            .p-multiselect-trigger {
              background-color: var(--inputBackgroundColor) !important;
              border: none;
            }
          }
        }

        .inventory-type {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 20px;
        }
      }
    }

    .footer {
      border-top: 2px solid var(--systemBackground);
      padding: 20px 0;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
  }
}
