@import "../../../../../theme/variables.scss";

.image-upload-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 10px;
  min-width: 620px;
  min-height: 416px;

  .location-selection {
    display: flex;
    gap: 10px;

    .p-dropdown {
      min-width: 200px;
      flex-grow: 1;
    }
  }

  .file-drop-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .selected-image-wrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;

      .image-preview {
        width: 100%;
        height: 400px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-color: var(--systemBackground) !important;
        border-radius: 8px !important;
      }

      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .multiple-files-msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      p {
        margin: 0;

        &.remove-all {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
