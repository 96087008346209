@import "../../../theme/variables.scss";

.side-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  transition:
    width 300ms ease-in-out,
    opacity 300ms ease-in-out;
  width: var(--width);

  .side-menu-container {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    background-color: $systemForeground;
    // border-right: $defaultBorder;
    overflow: hidden;

    .navigation {
      display: flex;
      flex-direction: column;

      .dashboard-accordion-tab {
        .accordion-dashboard-wrapper {
          .title-header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 30px;
            color: var(--Blue-gray-500, #4e5ba6);
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;

            .nav-type-button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              border: 0px solid;
              border-radius: 20px;
              background: var(--Blue-gray-100, #eaecf5);
              .nav-type-icon {
                font-size: 16px;
                color: $indigo;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .inventory-accordion-tab {
        .accordion-inventory-wrapper {
          .title-header {
            color: var(--Blue-gray-500, #4e5ba6);
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }

      .database-accordion-tab {
        .accordion-database-wrapper {
          .title-header {
            color: var(--Blue-gray-500, #4e5ba6);
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }

      .nav-type {
        display: flex;
        flex-direction: column;
        .nav-type-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          .nav-type-name {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: $textTertiary;
            text-transform: uppercase;
          }
        }
        .nav-type-content-container {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          overflow: auto;
          overflow-x: hidden;
          .nav-type-item {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 10px 30px;

            .nav-icon-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              border-radius: 5px;
              padding: 4px;
              .nav-item-icon {
                &.dashboard-item {
                  color: #ff8b62 !important;
                }
                &.database-item {
                  color: #7893ff !important;
                }
              }
              &.dashboard-item {
                background: var(--Orange-100, #ffebe3);
              }
              &.database-item {
                background: var(--Indigo-100, #e1ebff);
              }
            }

            .nav-item-name {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              color: $textTertiary;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .side-menu-size-controller {
    position: relative;
    border-left: 2px solid;
    width: 10px;
    border-color: transparent;
    transition: border 100ms linear;
    cursor: e-resize;
    &:hover {
      border-color: $primary;
    }
    .side-menu-resizer {
      transform: translateX(-14px);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border: 0px;
      background-color: $systemForeground;
      border-radius: 50%;
      top: 136px;
      box-shadow: $sideMenuSizeIconShadow;

      .side-menu-resizer-icon {
        font-size: 16px;
        color: $indigo;
        transition: rotate 100ms linear;
      }
    }
  }
}

.p-panelmenu .p-panelmenu-content {
  border: 0px !important;
}
.p-menuitem-link {
  display: flex;
  gap: 10px;
  align-items: center;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: none;
  box-shadow: none !important;
}
.p-menuitem-text {
  color: #4e5ba6;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.p-menuitem,
.p-panelmenu-header {
  &[aria-expanded="true"] {
    .p-menuitem-icon.chevron-right {
      transform: rotate(90deg);
    }
  }
  .p-menuitem-icon.chevron-right {
    transition: 150ms linear;
  }
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 0.75rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
  padding: 0px 22px 0 22px;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #4e5ba6;
}
.dcp-panel-menu {
  padding: 0px !important;
  overflow: auto !important;
  height: calc(100vh - 65px);

  .p-panelmenu-panel {
    .p-panelmenu-header {
      // &.p-highlight {
      //   .p-menuitem-icon {
      //     transform: rotate(90deg);
      //   }
      // }

      .p-panelmenu-header-content {
        .p-panelmenu-header-link {
          background-color: $systemForeground !important;
          border: none !important;
          display: flex;
          padding: 0px 1.25rem 0px 30px !important;
          border-radius: 0px !important;

          // .p-menuitem-icon {
          //   transition: 150ms linear;
          // }

          .dcp-menu-item-label {
            font-weight: 600 !important;
            padding: 1.25rem 0 1.25rem 1.25rem !important;
          }
          .dcp-menu-item-label {
            font-weight: 600 !important;
            padding: 1.25rem 0 1.25rem 0px !important;
          }

          .btn-new-dashboard {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            background: var(--dashboardMainSeparatorColor);
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0px 15px 0 22px !important;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .dcp-menu-item-label {
    flex: 1;
    color: var(--sideMenuLabelColor) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .dcp-dashboard-nav-item-container {
    display: flex;
    padding: 0 1.25rem 0 0 !important;
    gap: 10px;

    .nav-item-area {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
    }
  }
  // .new-dashboard-pop-up {
  //   position: absolute;
  //   min-width: 200px;
  //   background-color: var(--surface-a);
  //   display: flex;
  //   flex-direction: column;
  //   padding: 10px 0px;
  //   z-index: 1000;
  //   border-radius: 6px;
  //   background: var(--systemForeground);
  //   box-shadow:
  //     0px 2px 4px -2px rgba(16, 24, 40, 0.06),
  //     0px 4px 8px -2px rgba(30, 40, 60, 0.1);

  //   .new-dashboard-pop-up-title {
  //     display: flex;
  //     padding: 4px 20px;
  //     align-items: center;
  //     gap: 30px;
  //     white-space: nowrap;
  //     color: var(--textPrimary);
  //     font-size: 10px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: normal;
  //     margin-bottom: 8px;
  //   }

  //   .new-dashboard-pop-up-action {
  //     display: flex;
  //     padding: 7px 20px;
  //     align-items: center;
  //     gap: 10px;
  //     color: var(--textTertiary);
  //     font-size: 12px;
  //     font-weight: 500;
  //     line-height: 18px;
  //     cursor: pointer;
  //   }
  // }
  // .icon-container {
  //   display: flex;
  //   width: 24px;
  //   height: 24px;
  //   padding: 4px 3.999px 4px 4px;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 5px;

  //   &.dashboards {
  //     background-color: var(--dashboardSideMenuIconBackgroundColor);
  //   }
  //   &.databases {
  //     background-color: var(--databaseSideMenuIconBackgroundColor);
  //   }
  //   &.reports {
  //     background-color: var(--databaseSideMenuIconBackgroundColor);
  //   }
  // }
}

.main-layout-splitter-panel-side-menu-container {
  background-color: var(--systemForeground);
}
