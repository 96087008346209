@import "../../../../../theme/variables.scss";

.dialog-audit-count-details {
  display: flex;
  height: 100% !important;
  max-height: 98% !important;
  width: 99% !important;
  padding: 0px;
  .p-dialog-content {
    border-radius: 8px !important;
    padding: 0px !important;
  }
  .p-dialog-header {
    display: none;
  }
  .barcode-read {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 400;

    .barcode-read-content {
      background-color: var(--systemForeground);
      display: flex;
      height: -moz-fit-content;
      width: 100%;
      flex-grow: 1;
      border-radius: 6px;

      .barcode-details {
        display: flex;
        flex-grow: 1;
        width: 100%;
        max-width: 470px;
        height: 100%;
        flex-direction: column;
        padding: 30px 30px;
        gap: 15px;
        background-color: var(--systemForeground);
        border-radius: 6px 0px 0px 6px;
        overflow: auto;

        .return-option-wrapper {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .icon-return {
            display: flex;
            cursor: pointer;
          }
          .return-text-title {
            font-weight: 700;
            font-size: 16px;
            color: $textPrimary;
          }
        }

        .barcode-form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .identifier-location-label {
            color: (--textPrimary);
            font-weight: 500;
            font-size: 14px;
          }

          .identifier-location-text-area {
            color: (--textPrimary);
            font-weight: 500;
            font-size: 14px;
          }
          .content-location-text-area {
            background-color: var(--systemBackground);
            color: (--textPrimary);
            font-weight: 500;
            font-size: 14px;
            border-radius: 8px;
          }

          .icon-add-sku {
            cursor: pointer;
          }

          .icon-add-sku-blocked {
            cursor: not-allowed;
            filter: brightness(1.5);
          }

          .content-location {
            font-weight: 600;
            font-size: 16px;
            border-radius: 4px;

            .reliability-reliable {
              background-color: #8cd8c0;
              border-radius: 4px;
              color: var(--textPrimary);
            }

            .reliability-lessReliable {
              background-color: #ffe78a;
              border-radius: 4px;
              color: var(--textPrimary);
            }

            .reliability-unreliable {
              background-color: #fba6ba;
              border-radius: 4px;
              color: var(--textPrimary);
            }
          }

          .inventory-link {
            width: fit-content;
            text-decoration: underline;
            font-weight: 500;
            font-size: 16px;
            color: #4146ff;
            cursor: pointer;
          }

          .inventory-username {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .issue-details-wrapper {
          gap: 6px;
          display: flex;
          flex-direction: column;
          .issue-details-text {
            display: flex;
            background-color: #f5f8ff;
            border-radius: 8px;
            align-items: center;
            padding: 10px 14px 10px 14px;
          }
        }
        .input-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 50%;
          gap: 6px;

          &.lpn-status {
            flex-direction: row;
            gap: 10px;
            align-items: center;
          }

          .header-title-section {
            display: flex;
            justify-content: space-between;
          }
        }
        .input-container.location-status {
          display: flex;
          align-items: flex-end;
          gap: 6px;
          border-radius: 20px;

          .content-status {
            display: flex;
            gap: 6px;
            align-items: center;
            border-radius: 20px;

            .location {
              display: flex;
              flex-direction: row;
              gap: 8px;
              padding: 6px 10px;
              border-radius: 20px;
              height: 30px;
              width: fit-content;

              &.-audited {
                background: var(--blue-100);

                p {
                  margin: 0;
                  color: var(--blue-500);
                  font-size: 14px;
                  font-weight: 600;
                }
              }

              &.-matches {
                background: var(--green-100);

                p {
                  margin: 0;
                  color: var(--green-500);
                  font-size: 14px;
                  font-weight: 600;
                }
              }

              &.-divergent {
                background: var(--red-100);

                p {
                  margin: 0;
                  color: var(--red-500);
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .input {
          flex-grow: 1;

          .p-inputtext,
          .p-dropdown,
          .p-inputnumber {
            color: #000;
            width: 100%;
            font-weight: 600;
            &.lpn-status {
              border: 1px solid #f5f8ff;
              background-color: var(--dropdown-selected-background-color);
              .p-dropdown-label {
                color: var(--dropdown-selected-text-color);
              }
            }
          }
        }

        .sku-wrapper {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          flex-direction: row;
          .icon-add {
            display: flex;
            cursor: pointer;
          }
        }

        .dcp-form {
          padding: 0;
          gap: 30px !important;
          .form-row {
            flex-wrap: wrap;
            .location-info {
              display: flex;
              flex-direction: row;
            }
          }
        }

        // itens-table component
        .itens-table {
          width: 100%;
          height: 100%;
        }

        .content-body {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 5px;
          align-items: center;
          .dcp-table {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .itens-table {
              .p-datatable .p-datatable-tbody > tr > td {
                padding: 22px 10px !important;
                text-align: center;

                .result-wrapper,
                .sku-wrapper {
                  align-items: center;
                  justify-content: center;
                }

                .icon-wrapper {
                  display: flex;
                  justify-content: center;
                  width: 54px;
                  gap: 12px;

                  .icon-row-delete,
                  .icon-row-edit {
                    cursor: pointer;
                    transition: 0.2s;
                  }
                  .icon-row-delete:hover,
                  .icon-row-edit:hover {
                    opacity: 0.8;
                  }

                  .icon-row-blocked {
                    cursor: not-allowed;
                    filter: brightness(1.5);
                  }
                }
              }
            }
          }
        }
        .other-locations-wrapper {
          .location-street {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 8px 0px;
            border-left: 2px;
            .locations-tag {
              width: 3px;
              height: 15px;
              border-radius: 6px;
              background-color: #fd7584;
            }
            .locations-name {
              font-weight: 600;
              font-size: 14px;
              color: $inputFontColor;
            }
          }
        }
      }

      .barcode-image {
        display: flex;
        gap: 15px;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        background: var(--systemBackground);
        overflow: auto;

        .location-image-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          padding: 15px;
          justify-content: space-between;

          .location-image-header-buttons {
            display: flex;
            justify-content: flex-start;

            .p-button {
              margin: 0px;
              padding: 5px;
            }
          }

          .location-select-dropdown {
            display: flex;
            align-items: center;
            gap: 20px;

            .location-name-dropdown,
            .location-users-dropdown {
              width: 200px;
              background-color: var(
                --inputBackgroundColorWhiteOnLightTheme
              ) !important;

              .p-dropdown-label {
                background-color: var(
                  --inputBackgroundColorWhiteOnLightTheme
                ) !important;
              }

              .p-dropdown-trigger {
                background-color: var(
                  --inputBackgroundColorWhiteOnLightTheme
                ) !important;
              }
            }

            .icon {
              .right-setting-exists:hover {
                cursor: pointer;
              }
              .right-setting-does-not-exists:hover {
                cursor: not-allowed;
              }
            }
          }

          .location-image-name {
            font-weight: 600;
            font-size: 16px;
            color: var(--textPrimary);
          }
        }

        .location-image {
          display: flex;
          justify-content: center !important;
          align-items: center;
          height: 90%;
          padding: 23px 35px 23px 35px !important;
          .location-img {
            height: 100% !important;
            width: 100% !important;
            cursor: "grab";
          }
        }

        .p-image-preview-indicator:hover {
          border-radius: 10px;
        }

        .location-image-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 23px 35px 23px 35px !important;
        }

        .image-gallery {
          display: flex;
        }

        .main-image-container {
          flex: 1;
          text-align: center;
        }

        .paginator {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .paginator-content {
            font-weight: 600;
          }
        }
      }
    }

    hr {
      width: 100%;
      border: 0;
      height: 1px;
      margin: 30px 0px;
      background: #d5d9eb;
    }
  }
}

.dcp-popup-delete-confirmation {
  display: flex;
  width: 334px;
  height: 155px;
  .p-overlaypanel-content {
    padding: 18px;
    .popup-wrapper {
      display: flex;
      flex-direction: column;
      .popup-header {
        display: flex;
        height: 100%;
        display: flex;
        justify-content: space-between;
        gap: 18px;
        .popup-icon-wrapper {
          padding: 0px 0px 5px;
          .popup-icon-delete {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ffe8ea;
            border: 8px solid #ffeff0;
          }
        }
        .popup-body-wrapper {
          .popup-title-wrapper {
            display: flex;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            .popup-title-message {
              color: var(--textPrimary);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }

          .popup-description-wrapper {
            .popup-body-message {
              color: var(--textTertiary);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
      .popup-footer-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
      }
    }
  }
}
