.Toastify__toast-container--top-right {
  top: 70px;
}

.Toastify__toast-theme--light {
  background: var(--systemForeground);
  color: var(--textPrimary);
}

.toast-icon-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 16px;
  background-color: (--systemForeground);

  .toast-message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: "Quicksand";

    .toast-title {
      font-weight: 700;
      color: var(--textPrimary);
    }
  }
}

.Toastify__progress-bar--success {
  background: #03ceb7;
}
.Toastify__progress-bar--info {
  background: #6172f3;
}
.Toastify__progress-bar--warning {
  background: #dd9303;
}
.Toastify__progress-bar--error {
  background: #f9485b;
}
