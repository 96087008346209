.database-details-container {
  display: flex;
  height: 100%;
  min-width: 500px;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;

  .database-details-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 1rem 1rem 0;
  }

  .database-details-table-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 20px 20px 30px 20px;
    ;

    .database-details-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;

      .input-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5px;
        width: 100%;
      }

    }

    .database-details-table,
    .p-datatable {
      display: flex;
      flex-direction: column;
      width: 100% !important;

      .user-cell {
        display: flex;
        align-items: center;
      }

      .column-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;

        .column-actions {
          display: flex;
          height: 30px;
          align-items: center;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            padding: 10px;
            transition: 0.2s;
            border-radius: 8px;
            cursor: pointer;

            .icon-edit {
              color: var(--tableIconColor) !important;
            }

            &:hover {
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
            }
          }
        }
      }
    }
  }
}