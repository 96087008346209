@import "../../../../theme/variables.scss";

.andon-board {
  height: 100%;
  width: 100%;
  padding: 20px;
  background: $systemBackground;
  position: relative;

  &.full {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  header {
    display: flex;
    padding: 0 20px;
    justify-content: flex-end;

    .header-buttons {
      display: flex;
      gap: 10px;
    }
  }

  .grid-item-wrapper {
  }

  .grid-item {
    height: 100%;
    padding: 8px;
    background: $systemForeground;
    border: solid 1px $painelBorderColor;
    border-radius: $inputBorderRadius;

    &.no-bg {
      padding: 0;
      background: none;
      border: none;
    }

    &:hover {
      .title-wrapper {
        .actions {
          display: block;
        }
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: flex-end;
      height: 22px;
      align-items: center;

      .actions {
        display: flex;
        gap: 2px;
        display: none;

        .p-button {
          padding: 5px;
          height: fit-content;
        }
      }
    }

    .content-wrapper {
      height: 100%;
    }
  }

  .text-edit-op {
    .p-overlaypanel-content {
      padding: 10px;
      width: 300px;

      .p-inputtext {
        width: 100%;
      }
    }
  }

  .options-menu {
    .p-overlaypanel-content {
      padding: 0;
      display: flex;

      .detail {
        width: 14px;
        background-color: #6468ff;
        border-radius: 8px 0 0 8px;
      }

      .icons {
        padding: 6px 10px;
        display: flex;
        gap: 10px;

        .p-button {
          padding: 8px;
          transition: none;
          border: none;

          &:hover {
            padding: 8px;
            border: none;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
