.grafana {
  height: 100%;
  width: 100%;
  overflow: hidden;

  iframe {
    height: 100%;
    width: 100%;
  }
}
