@import "../../../../../../../../theme/variables.scss";

.availability-horizontal-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .bar-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
    gap: 20px;

    .bar {
      position: relative;
      height: 100%;
      width: 100%;

      .background-fill,
      .value {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 3px;

        &.background-fill {
          background: #f5f5fd;
        }
      }

      .exemple-bar-wrapper {
        height: 6px;
        width: 100%;

        .exemple-bar {
          height: 100%;
          width: 100%;
          background: #f5f5fd;
        }
      }
    }

    .percentage-label {
      color: $textSecondary;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .legend {
    display: flex;
    column-gap: 10px;
    row-gap: 6px;
    flex-wrap: wrap;

    p {
      font-weight: 500;
      font-size: 14px;
      color: var(--textSecondary);
      margin: 0;
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }
  }
}
