@import "../../../../theme/variables.scss";

.report-view {
  width: 100%;
  height: 100%;
  padding: 20px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  margin-bottom: 10px;

  .main-content-container {
    display: flex;
    flex-direction: column;
    background: $systemForeground;
    border-radius: 6px;
    border: solid 1px $painelBorderColor;

    .p-datatable {
      .p-paginator {
        background: none;
        border: none;
      }
    }

    .title-template {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        color: var(--textPrimary);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
    }

    .actions {
      display: flex;

      .options-wrapper {
        display: flex;
        gap: 20px;
        .search-input {
          display: flex;
          align-items: center;
          width: 220px;
          height: 40px;
          background: var(--inputBackgroundColor);
          border-radius: 8px;
          padding: 10px 14px;
        }

        .icon-options {
          display: flex;
          gap: 5px;
          margin-right: 20px;
          .btn-column {
            border: none;
            background: none;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            transition: 0.2s;

            &:hover {
              border: none;
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
              box-shadow: none;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .btn-edit {
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            transition: 0.2s;
          }
          .btn-edit:hover {
            padding: 10px;
            border-radius: 8px;
            background: var(--systemBackground);
          }
          .btn-edit:focus {
            box-shadow: none;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 20px;

        .btn-download-database-list {
          gap: 0px;
        }

        .p-button-link {
          color: var(--primary);
        }
      }
    }
  }
}

// Overlays
.download-list-columns-report-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--systemForeground);

  .p-overlaypanel-content {
    padding: 0px;
    background-color: var(--tableBodyBackground);
    color: var(--textPrimary);
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid var(--systemForeground);
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-download-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;

        .popup-excel-download-option,
        .popup-pdf-download-option,
        .popup-csv-download-option {
          cursor: pointer;
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;
          transition: 0.2s;

          .popup-download-label {
            cursor: pointer;
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          &:hover {
            background-color: var(--systemBackground);
          }
        }
      }
    }
  }
}

.download-options-form {
  padding: 0;
}
