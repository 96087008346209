@import "../../../theme/variables.scss";

.dcp-form {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  padding-bottom: 20px;
  flex-direction: column;

  .form-row {
    width: 100%;
    display: flex;
    gap: 20px;

    .input-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      transition: 0.2s;

      .identifier {
        font-weight: 500;
        font-size: 14px;
        color: $textSecondary;
        margin-bottom: 6px;
        white-space: nowrap;
      }

      input::placeholder {
        font-size: 14px;
      }

      .input {
        flex: 1;
        .p-inputtext,
        .p-dropdown {
          border: 1px solid #f5f8ff;
          transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;

          .p-inputnumber {
            width: 100%;
          }
        }
        .p-inputtext:hover,
        .p-dropdown:hover {
          border: 1px solid #6366f1;
        }

        .p-inputtext:focus,
        .p-dropdown:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #c7d2fe;
          border-color: #6366f1;
        }

        .p-dropdown-label,
        .p-dropdown-label:hover,
        .p-dropdown-label:focus {
          border: none;
        }
      }
    }

    .input-container-switch {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;

      .identifier {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        color: $textSecondary;
        margin-bottom: 6px;
        white-space: nowrap;
      }

      .inputswitch {
        display: flex;
        flex-grow: 1;
        .p-inputtext,
        .p-inputnumber {
          width: 100%;
        }
      }
    }
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}
