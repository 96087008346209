@import "../../../theme/variables.scss";
@import "../../../theme/animations.scss";

.return {
  height: 100%;

  .content-container {
    background-color: $systemForeground;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      padding: 18px 30px;
      border-bottom: 1px solid var(--systemBackground);
      p {
        color: var(--textPrimary);
        font-weight: 600;
        font-size: 16px;
        margin: 0;
      }
    }

    .content {
      flex-grow: 1;

      display: flex;
      flex-direction: column;

      .filters {
        padding: 20px 30px;

        & > p {
          margin: 0;
          margin-bottom: 20px;

          font-weight: 500;
          font-size: 14px;
          color: var(--textSecondary);
        }

        .search-btn-container {
          flex-grow: 0;
          justify-content: flex-end;
        }

        .filter-form {
          max-width: 1000px;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        & > p {
          padding: 20px 30px;
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          color: var(--textSecondary);
        }
      }

      .datatable-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    .footer {
      padding: 10px 30px;
      border-top: 1px solid var(--systemBackground);
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
  }
}
