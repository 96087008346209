@import "../../../../../../../../theme/variables.scss";

.inbound-heat-map {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.apexcharts-text tspan {
  stroke: $textSecondary;
  font-family: Quicksand !important;
  font-weight: lighter !important;
}
