@import "../../../../../../../../theme/variables.scss";

.productivity-hour-bar {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  .chart {
    height: 100%;
  }

  .legend {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    p {
      color: $textSecondary;
      margin: 0;
      margin-top: 10px;
      span {
        font-weight: 600;
      }
    }
  }
}
