.production-count {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .label {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--textSecondary);
    text-align: center;
  }

  .value {
    font-size: 6rem;
    font-weight: 700;
    color: var(--textPrimary);
  }
}
