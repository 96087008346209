@import "../../../../theme/variables.scss";

.home-layout {
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: 34px;
  margin-top: 36px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}

.inventory-layout {
  display: flex;
  padding: 34px;
  width: 100%;
  height: 100%;

  .layout-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    border-radius: 8px;
    border: solid 1px var(--painelBorderColor);

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      padding: 19px 30px;
      height: 60px;
      width: 100%;
      background: $systemForeground;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid var(--systemBackground);

      .title {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        color: $textPrimary;
        margin: 0;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
      }
    }

    .botton-container {
      display: flex;
      flex-grow: 1;
      height: 100%;
      border-top: 1px solid var(--systemBackground);

      .search-rack {
        display: flex;
        flex-grow: 1;
        max-width: 300px;
        flex-direction: column;
        padding: 18px 30px;
        background-color: #f8f9fc;
        border-radius: 0 0px 0px 6px;

        .rack-types-title {
          font-weight: 700;
          font-size: 16px;
          color: $textPrimary;
          margin-bottom: 10px;
        }

        .description {
          font-size: $defaultFontSize;
          font-weight: 400;
          color: var(--gray-500);
          margin-bottom: 18px;
        }

        .search-input {
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          background-color: var(--inputBackgroundColor, #fff);
          padding: 0px 12px;

          .icon-search {
            display: flex;
            justify-content: space-between;
          }
          .p-inputtext {
            flex-grow: 1;
            background-color: #fff;
          }
        }

        .new-rack-button {
          font-size: 14px;
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          gap: 8px;
        }

        .racks-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          max-height: 480px;
          overflow-y: auto;
          margin-right: -26px;

          .rack {
            display: flex;
            align-items: center;
            gap: 15px;
            background: #e5e6ff;
            border-radius: 8px;
            padding: 12px;
            cursor: pointer;
            height: 40px;
            width: 240px;

            * {
              transition: 300ms ease-in-out;
            }

            .rack-tag {
              width: 8px;
              height: 18px;
              border-radius: 2px;
              background: var(--gray-400);
            }

            .rack-name {
              font-weight: 600;
              font-size: 16px;
              color: $inputPlaceholderColor;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &.active-rack,
            &:hover {
              .rack-tag {
                background-color: var(--rackColor);
              }

              .rack-name {
                color: var(--rackColor);
              }
            }
          }
        }
      }

      .rack-configure-wrapper {
        display: flex;
        flex-grow: 4;
        flex-direction: column;
        gap: 20px;
        background-color: $systemForeground;

        .rack-form {
          flex: 1;
          display: flex;
          flex-direction: column;

          .rack-form-content {
            flex: 1;
            display: flex;

            .dcp-form {
              display: flex;
              gap: 10px;
              flex-grow: 1;

              .form-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: scroll;
                padding: 30px 40px;

                .form-content-title {
                  font-weight: 600;
                  font-size: 16px;
                  color: $textPrimary;
                  margin-bottom: 20px;
                }

                .mixed-shelves-container {
                  .form-row {
                    align-items: center;

                    .shelf-index {
                      font-weight: 500;
                      font-size: 14px;
                      color: $textSecondary;
                      white-space: nowrap;
                    }
                  }
                }

                .form-column {
                  width: 49%;

                  .input-container {
                    height: 80px;
                  }
                }

                .form-row {
                  width: 100%;
                  display: flex;
                  gap: 20px;

                  .input-container {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    &.rack-name {
                      flex-grow: 1;
                    }

                    &.rack-color {
                      flex-grow: 0;

                      .p-colorpicker-preview {
                        height: 41px;
                        width: 41px;
                      }
                    }

                    .input {
                      flex-grow: 1;

                      .p-inputtext,
                      .p-inputnumber {
                        width: 100%;
                        border: none !important;
                      }
                    }
                  }
                }
              }
            }
            .demo-rack-content {
              display: flex;
              flex-direction: column;
              width: 50%;
              border-left: 1px solid var(--systemBackground);
              background-color: var(--rackExampleBackgroundColor);

              .header {
                display: flex;
                justify-content: space-between;
                padding: 10px 10px;
                color: var(--textTertiary);

                span {
                  font-size: $defaultFontSize;
                  font-weight: 500;
                  color: var(--textTertiary);
                }
              }

              .generated-rack {
                padding: 10px 10px 72px;
                height: 100%;
                max-width: 1280px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow-x: auto;

                .wrapper {
                  display: flex;
                  flex-direction: column;
                  margin-left: -12px;

                  &:first-child {
                    margin: 0;
                  }

                  .header {
                    display: flex;
                    justify-content: space-between;
                    width: 80%;
                    background: var(--gray-100);
                    border-radius: 8px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                    padding: 8px;

                    .name {
                      display: flex;
                      .color {
                        height: 18px;
                        width: 8px;
                        border-radius: 2px;
                        margin-right: 23px;
                      }
                    }

                    .icon {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .rack-form-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border-top: 1px solid var(--systemBackground);
            padding: 10px 30px;
            height: 60px;

            .dcp-btn-wrapper-delete-rack {
              display: flex;
            }

            .dcp-btn-wrapper-manage-racks {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }
}
