@import "../../../../theme/variables.scss";

.report-wrapper {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;

  .report-edit {
    display: flex;
    padding: 34px;
    width: 100%;
    height: 100%;

    .dcp-form {
      gap: 20px;
    }

    .section-title {
      color: $textPrimary;
      font-size: 16px;
      font-weight: 700;
    }

    .description {
      color: var(--textPrimary);
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0;
    }

    .report-edit-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .p-multiselect {
      border: none;
      background: var(--inputBackgroundColor);
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;

      &:hover {
        border: 1px solid var(--systemForeground);
        border-radius: 1px;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #c7d2fe;
        border-color: #c7d2fe;
      }

      .p-multiselect-label {
        border: none;
        border-radius: 8px 0px 0px 8px;
        background-color: var(--inputBackgroundColor);
      }

      .p-multiselect-trigger {
        background-color: var(--inputBackgroundColor) !important;
      }
    }

    .report-edit-content {
      background-color: $systemForeground;
      flex: 1;
      border-radius: 6px;
      display: flex;
      flex-direction: column;

      .content-container {
        flex: 1;
        display: flex;
        position: relative;
        border-top: 1px solid var(--systemBackground);

        .report-config {
          flex: 1;
          display: flex;
          flex-direction: column;
          position: relative;

          .internal {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
          }

          .report-config-header {
            padding: 20px 30px;
            border-bottom: 1px solid var(--systemBackground);
            background: $systemForeground;

            display: flex;
            flex-direction: column;
            gap: 10px;

            .option-container {
              display: flex;
              margin-top: 10px;
              gap: 20px;

              .option {
                display: flex;
                gap: 8px;
                width: 100%;

                .option-name {
                  color: var(--textPrimary);
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 5px;
                }
              }
            }
          }

          .report-config-body-container {
            flex: 1;
            padding: 20px 30px;
            background-color: var(--reportColumnsEditBackgroundColor);
            display: flex;
            flex-direction: column;
            gap: 20px;

            .p-panel-content {
              background-color: var(--reportEditBodyBackgroundColor);
              border: none;
            }

            .report-field-config-area {
              height: 100%;

              .report-config-body {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .p-panel-content {
                  padding: 0;
                }

                .report-column {
                  .header-title {
                    flex-grow: 1;
                    color: var(--textPrimary);
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    padding: 0;
                  }

                  .panel-header {
                    border-radius: 10px 10px 0 0;
                    padding: 15px 14px;
                    display: flex;
                    gap: 10px;
                    background: var(--reportColumnHeaderBackgroundColor);

                    &:hover {
                      cursor: pointer;
                    }

                    &.collapsed {
                      border-radius: 10px;
                    }
                  }

                  .report-column-content {
                    display: flex;
                    gap: 10px;
                    padding: 20px;

                    .p-dropdown,
                    .p-inputtext {
                      height: fit-content;
                      background-color: var(--inputBackgroundColor);
                    }

                    .left-side {
                      flex-grow: 0.5;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: center;
                      gap: 10px;

                      &.rule-empty {
                        width: 100%;
                      }

                      .match-selection {
                        width: 100%;
                      }

                      .new-rule-btn {
                        color: var(--reportBackIconColor);
                        font-size: 14px;
                        font-weight: 600;
                        width: fit-content;
                      }
                    }

                    .column-rules {
                      display: flex;
                      flex-direction: column;
                      flex-grow: 1.5;
                      gap: 10px;

                      .column-row {
                        display: flex;
                        gap: 10px;

                        .p-dropdown,
                        .p-inputtext {
                          flex-grow: 1;
                          width: 100%;
                          background-color: var(--inputBackgroundColor);
                        }
                      }

                      .trash-icon {
                        width: 40px;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;

                        &:hover {
                          cursor: pointer;
                          background: var(--systemBackground);
                        }
                      }
                    }
                  }

                  .footer {
                    border-top: 1px solid var(--systemBackground);
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;

                    .p-button {
                      color: #e91f35;
                      font-family: Quicksand;
                      font-size: 14px;
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }

        .side-bar {
          width: 400px;
          padding: 20px 30px;
          background-color: $systemForeground;
          border-left: 1px solid var(--systemBackground);
          display: flex;
          flex-direction: column;
          gap: 20px;

          .header {
            p {
              margin: 0;
            }

            .description {
              margin-top: 10px;
            }

            .p-dropdown {
              width: 100%;
              margin-top: 10px;
            }

            .field-list {
              display: flex;
              margin-top: 20px;
            }
          }

          .table-columns-list {
            position: relative;
            height: 100%;

            .scrollable-area {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              .field-skeleton,
              .fields-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
              }

              .field-wrapper {
                display: flex;
                align-items: center;
                padding: 15px 14px;
                gap: 10px;

                height: 50px;
                border-radius: 10px;
                background: var(--reportColumnHeaderBackgroundColor);
                transition: 0.2s;

                &:hover {
                  cursor: pointer;
                  background: var(
                    --reportDataSourceColumnBackgroundColorOnHover
                  );
                }

                .field-title {
                  margin: 0;
                  padding: 0;

                  color: $textPrimary;
                  font-size: 13px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .report-edit-content-footer {
        height: 60px;
        padding: 0px 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        background-color: $systemForeground;
        border-top: 1px solid var(--systemBackground);
        border-radius: 0 0 6px 6px;
      }
    }
  }
}
