.divider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .line {
    width: 100%;
    height: 100%;
    background-color: var(--gray-300);
    border-radius: 5px;
  }
}
