@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.header-wrapper {
  display: flex;
  flex-direction: column;
  .icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: auto;
    position: relative;
    bottom: 21px;
    .btn-column,
    .btn-details {
      height: 20px;
      border: none;
      background: none;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      transition: 0.2s;
      padding: 4px;
      &:active {
        background: none;
      }
      &:hover {
        cursor: pointer;
        border: none;
        border-radius: 8px;
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  h1 {
    color: var(--gray-900, #101828);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  .content-container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    min-height: 160px;

    .skeleton {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .label {
      color: var(--textTertiary);
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .field-text-wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .receiving-type-wrapper {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
    }

    .text {
      color: var(--gray-900, #101828);
      font-size: 16px;
      font-weight: 600;
    }

    .receiving-itens-field {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      color: var(--textPrimary);

      .item-storage {
        color: var(--Gray-700, #344054);
        text-align: center;
        border-radius: 3px;
        padding: 0px 4px;
        background: var(--Blue-gray-200, #d5d9eb);
      }

      .item-dock {
        display: flex;
        padding: 8px 5px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: var(--Blue-gray-500, #4e5ba6);
        color: var(--White, #fff);
      }

      // Receiving types
      .type-receiving {
        font-size: 16px;
        display: flex;
        gap: 6px;
        font-weight: 600;
        justify-content: center;
        align-items: center;

        .type-Sacaria {
          color: var(--primary-600, #4146ff);
        }
        .type-Bag {
          color: var(--pink-600, #dd2590);
        }
        .type-Granel {
          color: var(--orange-500, #ff642e);
        }
        .type-Peças {
          color: var(--green-500, #04aa77);
        }
        .type-Direta {
          color: var(--primary-600, #4146ff);
        }
        .type-Packing {
          color: var(--pink-600, #dd2590);
        }
        .type-Staging {
          color: var(--green-500, #04aa77);
        }
      }
    }

    #first-section {
      display: flex;
      gap: 10px;
      width: 15%;
      margin-right: 40px;

      &.last {
        height: 100%;
      }

      .info-wrapper {
        display: flex;
        gap: 10px;
        height: 50px;

        .field {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-height: 50px;

          .status-receiving {
            display: flex;
            flex-direction: column;
          }

          .dropdown {
            background: #f2f4f7;
            width: fit-content;
            border-radius: 20px;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 35px;

            .status-receiving {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .area-wrapper {
        display: flex;
        flex-direction: column;

        .areas {
          display: flex;
          flex: 1;
          gap: 8px;

          .area {
            background: #d5d9eb;
            padding: 0 4px;
            border-radius: 3px;

            color: var(--gray-700, #344054);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .time-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .row {
          display: flex;
          gap: 10px;

          .time {
            flex: 1;
            .content {
              display: flex;
              gap: 8px;
            }
          }
        }
      }

      .location-wrapper {
        display: flex;
        flex-direction: column;

        .bar-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .bar {
            height: 16px;
            width: 340px;
            display: flex;
            border-radius: 8px;
            background: #eaecf0;

            span {
              color: white;
              font-size: 12px;
              font-weight: 500;
            }

            .section {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .success {
              border-radius: 8px 0 0 8px;
              background: #03ceb7;
            }

            .error {
              background: #e91f35;
            }
          }
        }
      }
    }
  }
}

.database-list-columns {
  display: flex;
  flex-direction: column;
  background-color: var(--tableBodyBackground);
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--tableBodyBackground) !important;

  .p-overlaypanel-content {
    padding: 0px;
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid var(--systemBackground);
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-list-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        color: var(--textPrimary);

        .popup-column-list {
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;
          color: var(--textPrimary);

          .p-checkbox .p-checkbox-box {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            font-size: 12px;
          }
          .checkbox-icon {
            display: flex;
            width: 22px;
            height: 22px;
            padding: 2px;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--systemBackground) !important;
            border-radius: 8px;

            .p-checkbox .p-checkbox-box .p-highlight {
              border-color: #6468ff;
              background: #6468ff;
              color: #fff;
              border-radius: 4px;
            }
          }
          .p-checkbox .p-checkbox-box .p-checkbox-icon {
            font-size: 12px;
          }

          .column-list-label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
}
