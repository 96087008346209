.transform-weapper-content-dialog {
    max-height: 95% !important;
    height: 95% !important;


    .p-dialog-content {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;

        .transform-weapper-content {
            display: flex;
            gap: 5px;
        }

    }

    .transform-weapper-conten {
        display: "flex";
        text-align: "center";
        margin-bottom: "10px";
        gap: "5px";
        justify-content: "center";
    }
}