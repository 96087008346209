@import "../variables.scss";

.dcp-table {
  table {
    thead {
      height: 44px;
      tr {
        th {
          display: table-cell;
          vertical-align: middle;
          padding: 16px 24px;
          background-color: $tableHeaderBackground;
          text-align: left;
          border: 1px solid var(--tableTrBorderColor);
          border-width: 0 0 1px 0;
          font-weight: 700;
          color: var(--textPrimary);
          transition: box-shadow 0.2s;

          &[aria-sort="ascending"] {
            .p-sortable-column-icon {
              opacity: 1;
              pointer-events: all;
            }
          }

          &[aria-sort="descending"] {
            .p-sortable-column-icon {
              opacity: 1;
              pointer-events: all;
            }
          }

          &:hover {
            .p-sortable-column-icon,
            .p-column-filter {
              opacity: 1;
              pointer-events: all;
            }
          }

          .p-sortable-column-icon,
          .p-column-filter {
            pointer-events: none;
            opacity: 0;
            transition: 150ms ease-in;

            &:has(.p-column-filter-menu-button-active) {
              pointer-events: all;
              opacity: 1;
            }
          }

          .p-column-title {
            font-size: 14px;
            color: $textSecondary;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: $tableBodyBackground;
        td {
          padding: 10px 24px !important;
          font-weight: 400;
          font-size: 14px;
          color: $textSecondary;
          background-color: $tableBodyBackground;
        }

        &:hover {
          background-color: $tableRowHoverBackgroundColor;
        }
      }

      .table-actions {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $textSecondary;

        .table-action-button {
          display: flex;
          width: 35px;
          height: 35px;
          padding: 0;
          justify-content: center;
          align-items: center;
          background-color: $tableActionButtonBackgroundColor;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .table-action-button-icon {
            color: $tableActionButtonIconColor;
            width: 18px !important;
            height: 18px !important;
          }

          &:hover {
            background-color: $tableActionButtonHoverBackgroundColor;
            .table-action-button-icon {
              color: $tableActionButtonHoverIconColor;
            }
          }
        }
      }
    }
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background-color: $tableHeaderBackgroundHover;
  }
}
