@import "../../theme/variables.scss";
@import "../../theme/animations.scss";

.database-items-details {
  padding: 30px 30px 0px 30px;
  display: flex;
  gap: 20px;

  .database-items-details-header {
    background: $systemForeground;
    border: solid 1px $painelBorderColor;
    padding: 18px 24px 24px 24px;
    border-radius: 6px;
    display: flex;
    width: 100%;
    flex-direction: column;

    // box-shadow:
    //   0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    //   0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      align-content: flex-end;
      flex-wrap: wrap;

      .customized-buttons {
        display: flex;
        margin-left: auto;
        margin-right: 5%;
        gap: 15px;
      }

      h1 {
        color: var(--textPrimary);
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
  }
}
