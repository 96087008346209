@import "../../../theme/variables.scss";

.excel-upload-form {
  .upload-file-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .uploaded-file-extracted-headers-data {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: start;
    }

    .field-mapping-row {
      flex: 1;
      display: flex;
      align-items: center;
      .field-label,
      .excel-form-field {
        flex: 1;
        text-align: start;
      }
    }
  }
}
