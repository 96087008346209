@import "../../../../theme/variables.scss";

.floor-area-edit {
  padding: 30px;
  height: 100%;

  .main-content {
    background: $systemForeground;
    border-radius: $panelBorderRadius;
    border: solid 1px var(--painelBorderColor);
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
      flex-grow: 1;
      border-top: 1px solid var(--systemBackground);
      display: flex;
      flex-wrap: wrap;

      .form-container {
        background: $systemForeground;
        padding: 30px 24px;
        width: 100%;
        flex-basis: 300px;
        flex-grow: 1;

        .color-input-container {
          flex-grow: 0;
        }

        .title {
          color: var(--textTertiary);
          font-weight: 600;
          margin: 0;
          margin-bottom: 20px;
        }
      }

      .preview-container {
        background: var(--inventoryFloorAreaBackgroundColor);
        flex-grow: 3;
        flex-basis: 400px;

        overflow: auto;
        position: relative;
        display: flex;
        align-items: center;

        .preview-info {
          padding: 10px 20px;
          align-self: flex-start;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;

          p {
            margin: 0;
            color: var(--gray-400);
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 10px 30px;
      border-top: 1px solid var(--systemBackground);

      .right {
        display: flex;
        gap: 20px;
      }
    }
  }

  .example-op {
    .p-overlaypanel-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;

      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
