@import "../../../../theme/variables.scss";

.example-btn {
  display: flex;
  justify-content: center;
  font-weight: 500;
  max-width: fit-content;
  height: 28px;
  padding: 11px 5px;
  gap: 8px;
  border-radius: 15px;

  span {
    color: var(--textTertiary);
    font-weight: 500;
  }

  &:hover {
    background: var(--sideFormBackgroundColor);
    .rack-example-icon {
      color: var(--gray-500);
    }
  }
}
