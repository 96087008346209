@import "../../../theme/variables.scss";

.denied-access-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  transition: 0.2s;

  .denied-access-body {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .denied-access-message {
      font-size: 40px;
      font-weight: 700;
      color: var(--blue-gray-500, #4e5ba6);
      text-align: center;
      line-height: 35px;
      width: 480px;
      padding-bottom: 20px;
    }

    .description-access-denied {
      color: var(--textTertiaryColor);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 60px;
      width: 300px;
    }

    .denied-access-image {
      width: 545px;
      height: 258px;
    }

    .description-mistake {
      color: var(--textTertiaryColor);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding-top: 30px;
      padding-bottom: 60px;
      width: 451px;
    }

    .p-button-back {
      height: 40px;
      width: 300px;
      gap: 8px;
    }
  }
}

@media (max-width: $mobileBreakPoint) {
  .denied-access-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
    transition: 0.2s;

    .denied-access-body {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      .denied-access-message {
        font-size: 25px;
        font-weight: 700;
        color: var(--blue-gray-500, #4e5ba6);
        text-align: center;
        line-height: 25px;
        width: 294px;
        padding-bottom: 10px;
      }

      .description-access-denied {
        color: var(--blue-gray-500, #4e5ba6);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 60px;
        width: 300px;
      }

      .denied-access-image {
        width: 321px;
        height: 152px;
      }

      .description-mistake {
        color: var(--blue-gray-500, #4e5ba6);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 80px;
        width: 320px;
      }

      .p-button-back {
        height: 40px;
        gap: 8px;
      }
    }
  }
}
